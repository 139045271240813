import ServiceFinance from '../../services/ipanel/ServiceFinance.js'

export const namespaced = true

export const state = {
    tutorPayoutList: [],
    tutorPayoutCount: null,
    tutorPayoutDetail: null,

    tutorPayoutRequestList: [],
    tutorPayoutRequestCount: null,
    tutorPayoutRequestDetail: null,

    referrerPayoutList: [],
    referrerPayoutCount: null,
    referrerPayoutDetail: null,

    cachedPassword: "sad"
}

export const mutations = {
    SET_CACHED_PASSWORD(state, password) {
        state.cachedPassword = password
    },
    SET_TUTOR_PAYOUT_LIST(state, payouts) {
        state.tutorPayoutList = payouts
    },
    SET_TUTOR_PAYOUT_COUNT(state, count) {
        state.tutorPayoutCount = count
    },
    SET_TUTOR_PAYOUT_DETAIL(state, tutorPayoutDetail) {
        state.tutorPayoutDetail = tutorPayoutDetail
    },
    UNSET_TUTOR_PAYOUT_DETAIL(state) {
        state.tutorPayoutDetail = null
    },
    REMOVE_SINGLE_TUTOR_PAYOUT(state, tutor_id) {
        state.tutorPayoutList = state.tutorPayoutList.filter(
            tutorPayout => {
                if (tutorPayout.tutor_id_value != tutor_id) {
                    return tutorPayout;
                }
            }
        )
    },

    SET_TUTOR_PAYOUT_REQUEST_LIST(state, requests) {
        state.tutorPayoutRequestList = requests
    },
    SET_TUTOR_PAYOUT_REQUEST_COUNT(state, count) {
        state.tutorPayoutRequestCount = count
    },
    SET_REFERRER_PAYOUT_LIST(state, payouts) {
        state.referrerPayoutList = payouts
    },
    SET_REFERRER_PAYOUT_COUNT(state, count) {
        state.referrerPayoutCount = count
    },
    SET_REFERRER_PAYOUT_DETAIL(state, referrerPayoutDetail) {
        state.referrerPayoutDetail = referrerPayoutDetail
    },
}

export const actions = {
    getTutorPayoutList({commit}, {
        page, perPage, searchQuery, filterYear, filterMonth
    }) {
        ServiceFinance.getTutorPayoutList(page, perPage, searchQuery, filterYear, filterMonth)
            .then(resp => {
                commit('SET_TUTOR_PAYOUT_LIST', resp.data.results)
                commit('SET_TUTOR_PAYOUT_COUNT', resp.data.count)
            })
    },
    getTutorPayoutDetail({
        commit
    }, {
        tutor_id
    }) {
        ServiceFinance.getTutorPayoutDetail(tutor_id)
            .then(resp => {
                commit('SET_TUTOR_PAYOUT_DETAIL', resp.data)
            })
    },

    getTutorPayoutRequestList({commit}, {page, perPage, searchQuery}) 
    {
        ServiceFinance.getTutorPayoutRequestList(page, perPage, searchQuery)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_TUTOR_PAYOUT_REQUEST_LIST', resp.data.results)
                    commit('SET_TUTOR_PAYOUT_REQUEST_COUNT', resp.data.count)
                }
            })
    },


    getReferrerPayoutList({commit}, {
        page, perPage, searchQuery
    }) {
        ServiceFinance.getReferrerPayoutList(page, perPage, searchQuery)
            .then(resp => {
                commit('SET_REFERRER_PAYOUT_LIST', resp.data.results)
                commit('SET_REFERRER_PAYOUT_COUNT', resp.data.count)
            })
    },
    getReferrerPayoutDetail({
        commit
    }, {
        referral_code
    }) {
        ServiceFinance.getReferrerPayoutDetail(referral_code)
            .then(resp => {
                commit('SET_REFERRER_PAYOUT_DETAIL', resp.data)
            })
    },

}

export const getters = {
    getTutorById: (state) => (tutor_id) => {
        return state.tutorPayoutList.find(tutorPayout => tutorPayout.tutor_id_value == tutor_id)
    },
    getReferrerById: (state) => (referral_code) => {
        return state.referrerPayoutList.find(referrerPayout => referrerPayout.student1__referrer__referral_code == referral_code)
    }
}