import Vue from 'vue'
import Vuex from 'vuex'
import * as issue from './ipanel/issue.js'
import * as invoice from './ipanel/invoice.js'
import * as refund from './ipanel/refund.js'
import * as certificate from './ipanel/certificate.js'
import * as tutorStat from './cpanel/tutorStat.js'
import * as tutor from './ipanel/tutor.js'
import * as student from './ipanel/student.js'
import * as affiliate from './ipanel/affiliate.js'
import * as classes from './ipanel/classes.js'
import * as subject from './ipanel/subject.js'
import * as grade from './ipanel/grade.js'
import * as discount from './ipanel/discount.js'
import * as curriculumn from './ipanel/curriculumn.js'
import * as finance from './ipanel/finance.js'
import * as expense from './ipanel/expense.js'
import * as promotion from './ipanel/promotion.js'
import * as jobpost from './ipanel/jobpost.js'
import * as partner from './ipanel/partner'
import * as blog from './ipanel/blog.js'
import * as admin_user from './ipanel/admin_user.js'
import * as user from './user.js'
import * as op_tutorjob from './operation/op_tutorjob.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    issue,
    invoice,
    refund,
    tutorStat,
    certificate,
    tutor,
    student,
    classes,
    subject,
    grade,
    curriculumn,
    finance,
    discount,
    expense,
    promotion,
    jobpost,
    partner,
    blog,
    admin_user,
    user,
    op_tutorjob,
    affiliate
  }
})