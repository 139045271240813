import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_curriculumn: 'api/v1/ipanel/curriculums/',
}

export default {
    getCurriculumnList(perPage, page, searchQuery) {
      var relativeURL = url_list.url_curriculumn + '?page_size=' + perPage + '&page=' + page

      if (searchQuery) {
        relativeURL += '&search=' + searchQuery
      }
      return apiClient.get(relativeURL)
    },
    createCurriculumn(data) {
      return apiClient.post(url_list.url_curriculumn, data)
    },
    getCurriculumnDetail(curriculumn_id) {
      return apiClient.get(url_list.url_curriculumn + curriculumn_id + '/')
    },
    updateCurriculumn(form_data) {
      return apiClient.patch(url_list.url_curriculumn + form_data.curriculumn_id + '/', form_data.data)
    },
    
}