import ServiceBlog from '../../services/ipanel/ServiceBlog.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const namespaced = true

export const state = {
    count: 0,
    posts: [],
    categoryList: [],
    statusChoiceFields: [],
    selectedBlogId: null,
    selectedPost: null,
    editor: ClassicEditor,
    editorConfig: {
        toolbar: {
        items: [
            "bold",
            "italic",
            "BulletedList",
            "NumberedList",
            "undo",
            "redo"
        ]
        }
    },

}

export const mutations = {

    SELECT_BLOG(state, id){
        state.selectedBlogId = id
        var post = state.posts.filter(element=>{
            if (element.id == id){
                return element
            }
        })
        state.selectedPost = post[0]
    },

    ADD_POSTS(state, data){
        state.posts = data.results
        state.count = data.count
    },

    ADD_ALL_CATEGORIES(state, dataList){
        state.categoryList = []
        dataList.forEach(category=>{
            state.categoryList.push({ id: parseInt(category.id), text: category.title })
        })
    },

    ADD_POST_STATUS_CHOICES(state, dataList){
        state.statusChoiceFields = dataList
    }
}

export const actions = {
    getAllCategories({commit}){
        ServiceBlog.getAllCategories().then(response=>{
            if (response.status == 200){
                commit('ADD_ALL_CATEGORIES', response.data)
            }
        })
    },

    getPostStatusChoices({commit}){
        ServiceBlog.getPostStatusChoices().then(response=>{
            if(response.status == 200){
                commit('ADD_POST_STATUS_CHOICES', response.data)
            }
        })
    },

    createPost({commit}, form){
        return ServiceBlog.createPost(form).then(response=>{
            if (response.status == 201){
                return response.status
            }
        })
        .catch(error=>{
            console.log(error.response.data)
            return error.response.status
        })
    },
    updatePost({commit}, data){
        return ServiceBlog.updatePost(data.form, data.slug).then(response=>{
            if (response.status == 200){
                return response.status
            }
        })
        .catch(error=>{
            console.log(error.response.data)
            return error.response.status
        })
    },
    deletePost({commit}, slug){
        return ServiceBlog.deletePost(slug).then(response=>{
            if (response.status == 204){
                return response.status
            }
        })
        .catch(error=>{
            console.log(error.response.data)
            return error.response.status
        })
    },

    getAllPosts({commit}, {perPage, page, searchQuery}){
        return ServiceBlog.getAllPosts(perPage, page, searchQuery).then(response=>{
            if (response.status == 200){
                commit('ADD_POSTS', response.data)
                return response.status
            }
        })
    },
    selectBlog({commit}, id){
        commit('SELECT_BLOG', id)
    }
}

export const getters = {}