var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container my-4", staticStyle: { "font-size": "14px" } },
      [
        _c(
          "b-row",
          [
            _vm.categoryPostList.length > 0
              ? _c(
                  "b-col",
                  { attrs: { cols: "12", md: "9" } },
                  [
                    _vm.isSearching
                      ? _c("div", { staticClass: "bg-white shadow-sm p-4" }, [
                          _c(
                            "h4",
                            [
                              _vm._v(
                                "\n                        Search results for " +
                                  _vm._s(_vm.searchQuery) +
                                  "\n                        "
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "light" },
                                  on: { click: _vm.reset }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: "times-circle",
                                      variant: "danger"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.categoryPostList, function(post, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "bg-white shadow-sm p-4" },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "10" } },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      staticClass: "text-ip",
                                      staticStyle: { "font-size": "20px" },
                                      on: {
                                        click: function($event) {
                                          return _vm.goto(post)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(post.title) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c("small", [
                                    _c("i", {
                                      staticClass: "fa fa-eye",
                                      attrs: { "aria-hidden": "true" }
                                    }),
                                    _vm._v(
                                      " \n                                " +
                                        _vm._s(post.views) +
                                        " \n                            "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "mt-4 text-center" },
                          [
                            _c("b-pagination-nav", {
                              attrs: {
                                "link-gen": _vm.linkGen,
                                "number-of-pages": _vm.totalPage,
                                "use-router": ""
                              },
                              on: { change: _vm.fetchCategoryPostList }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              : _c(
                  "b-col",
                  { staticClass: "card", attrs: { cols: "12", md: "9" } },
                  [
                    _vm.isSearching
                      ? _c(
                          "div",
                          { staticClass: "bg-white shadow-sm p-4 mt-2" },
                          [
                            _c(
                              "h4",
                              [
                                _vm._v(
                                  "\n                        Search results for " +
                                    _vm._s(_vm.searchQuery) +
                                    "\n                        "
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "light" },
                                    on: { click: _vm.reset }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: "times-circle",
                                        variant: "danger"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.categoryPostList.length == 0 && _vm.isProcessing == true
                      ? _c(
                          "div",
                          [
                            _c(
                              "ContentLoader",
                              { attrs: { width: "500", height: "400" } },
                              [
                                _c("rect", {
                                  attrs: {
                                    x: "0",
                                    y: "10",
                                    rx: "5",
                                    ry: "5",
                                    width: "100%",
                                    height: "60"
                                  }
                                }),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    x: "0",
                                    y: "80",
                                    rx: "5",
                                    ry: "5",
                                    width: "100%",
                                    height: "60"
                                  }
                                }),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    x: "0",
                                    y: "150",
                                    rx: "5",
                                    ry: "5",
                                    width: "100%",
                                    height: "60"
                                  }
                                }),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    x: "0",
                                    y: "220",
                                    rx: "5",
                                    ry: "5",
                                    width: "100%",
                                    height: "60"
                                  }
                                }),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    x: "0",
                                    y: "290",
                                    rx: "5",
                                    ry: "5",
                                    width: "100%",
                                    height: "60"
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.categoryPostList.length == 0 &&
                    _vm.isProcessing == false
                      ? _c(
                          "div",
                          { staticClass: "bg-white shadow-sm p-4 mt-2" },
                          [_c("h4", [_vm._v("No post available")])]
                        )
                      : _vm._e()
                  ]
                ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { cols: "12", md: "3" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.searchPost($event)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "input-group input-full-width" },
                              [
                                _c("b-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "Search Here"
                                  },
                                  model: {
                                    value: _vm.searchQuery,
                                    callback: function($$v) {
                                      _vm.searchQuery = $$v
                                    },
                                    expression: "searchQuery"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "w-30",
                                        attrs: {
                                          type: "submit",
                                          variant: "primary"
                                        }
                                      },
                                      [_c("b", [_vm._v("Search")])]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "bg-white shadow-sm p-4 mx-3 mt-2" },
                      [
                        _c("span", { staticClass: "mt-2" }, [
                          _c("h6", [_c("b", [_vm._v("Categories")])])
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _c(
                              "b-link",
                              {
                                staticClass: "text-ip",
                                attrs: { to: { name: "version2-blog-list" } }
                              },
                              [
                                _vm._v(
                                  "\n                                All, \n                            "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.category_list, function(category, index) {
                          return _c(
                            "span",
                            { key: index },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass: "text-ip",
                                  on: {
                                    click: function($event) {
                                      return _vm.gotoCategory(category.text)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(category.text) +
                                      "\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.category_list.length - 1 != index
                                ? _c("span", [_vm._v(", ")])
                                : _vm._e()
                            ],
                            1
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.latestPostList.length > 0
                      ? _c(
                          "b-col",
                          { staticClass: "mt-4", attrs: { cols: "12" } },
                          [
                            _c("span", [
                              _c("h6", [_c("b", [_vm._v("Latest 6 Articles")])])
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.latestPostList.length > 0
                      ? _c(
                          "b-col",
                          { staticClass: "bg-white shadow-sm p-4 mx-3" },
                          _vm._l(_vm.latestPostList, function(post, index) {
                            return _c(
                              "span",
                              { key: index },
                              [
                                _c(
                                  "b-link",
                                  {
                                    staticClass: "text-ip",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto(post)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(post.title) +
                                        "\n                            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                index != _vm.latestPostList.length - 1
                                  ? _c("span", [_c("hr")])
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }