import ServiceAffiliate from '../../services/ipanel/ServiceAffiliate.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const namespaced = true

export const state = {
    referrerList: [],
    referrerCount: null,
    referrerDetail: null,

    editor: ClassicEditor,
    editorConfig: {
        toolbar: {
            items: [
                "bold",
                "italic",
                "BulletedList",
                "NumberedList",
                "undo",
                "redo"
            ]
        }
    }

}

export const mutations = {
    SET_REFERRER_LIST(state, referrers) {
        state.referrerList = referrers
    },
    UNSET_REFERRER_LIST(state) {
        state.referrerList = []
    },
    SET_REFERRER_COUNT(state, count) {
        state.referrerCount = count
    },
    SET_REFERRER_DETAIL(state, referrer) {
        state.referrerDetail = referrer
    },
    UNSET_STUDENT_DETAIL(state) {
        state.referrerDetail = null
    },
    ADD_REFERRER(state, referrer) {
        state.referrerList.unshift(referrer)
    },
    SET_UPDATED_REFERRER(state, data) {
        const referrer_id = data.id
        state.referrerList = state.referrerList.filter(referrer => {
            if (referrer.id == referrer_id) {
                Object.assign(referrer, data);
            }
            return referrer
        });
    },
    UPDATE_REFERRER_DETAIL(state, data) {
        state.referrerDetail = data
    }
}

export const actions = {
    getReferrerList({ commit }, { perPage, page, searchQuery}) {
        ServiceAffiliate.getReferrerList(perPage, page, searchQuery)
            .then(resp => {
                commit('SET_REFERRER_LIST', resp.data.results)
                commit('SET_REFERRER_COUNT', resp.data.count)
            })
            .catch(err => {
                console.log(err)
            })
    },

    createReferrer({
        commit
    }, data) {
        return ServiceAffiliate.creatReferrer(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_REFERRER', resp.data)
                    Vue.toasted.success('Referrer created Successfully');
                    return resp
                }
            })
            .catch(err => {
                console.log(err)
            })
    },

    getReferrerDetail({
        commit
    }, student_id) {
        return ServiceStudent.getStudentDetail(student_id)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_STUDENT_DETAIL', resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const getters = {}