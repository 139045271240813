import ServicePromotion from '../../services/ipanel/ServicePromotion.js'
export const namespaced = true

export const state = {
  promotionList: [],
  promotionCount: null,
}


export const mutations = {
    SET_PROMOTION_LIST(state,data){
        state.promotionList = data
    },
    SET_PROMOTION_COUNT(state, count){
        state.promotionCount = count
    },
    UPDATE_PROMOTION_LIST(state, data){
      const promotion_id = data.id
      state.promotionList = state.promotionList.filter(promotion => {
          if(promotion.id == promotion_id){
             Object.assign(promotion, data);
          }
          return promotion
      });
    },
    ASSIGN_CREATED_PROMOTION(state,data){
        state.promotionList.push(data)
    }
}

export const actions = {
    getPromotionList({ commit }, {perPage, page, searchQuery} ) {
        ServicePromotion.getPromotionList(perPage, page, searchQuery)
          .then(resp => {
              console.log(resp);
            commit('SET_PROMOTION_LIST', resp.data.results)
            commit('SET_PROMOTION_COUNT', resp.data.count)
          })
          .catch(err => {
            console.log(err)
          })
    },

    createPromotion({commit}, data){
      return ServicePromotion.createPromotion(data)
      .then(resp => {
        if(resp.status == 201){
            console.log(resp);
            commit('ASSIGN_CREATED_PROMOTION',resp.data)
            Vue.toasted.success('Promotion Created Successfully.');
            return resp
        }
      })
    },
    updatePromotion({ commit },{ id , data }){
      return ServicePromotion.updatePromotion({
        id:id,
        data:data
      })
      .then(resp => {
        if(resp.status == 200){
          Vue.toasted.success('Promotion Updated Successfully.');
          commit('UPDATE_PROMOTION_LIST',resp.data)
          return resp
        }
      })
    },
    deletePromotion({commit},{id}){
        return ServicePromotion.deletePromotion(id)
        .then(resp => {
          if (resp.status==204){
            Vue.toasted.error('Promotion Deleted Successfully.');
          }
          return resp
        })
      },
}