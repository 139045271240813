import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_admin: '/api/v1/ipanel/admin-user/',
  url_group: '/api/v1/ipanel/admin-user/get-permission-groups/',
  url_permission: '/api/v1/ipanel/admin-user/get-permission-list/',
}

export default {
    getGroupList() {
      var relativeURL = url_list.url_group
      return apiClient.get(relativeURL)
    },
    getPermissionList() {
      var relativeURL = url_list.url_permission
      return apiClient.get(relativeURL)
    },
    getAdminUserList(perPage,page) {
      var relativeURL = url_list.url_admin + '?page_size=' + perPage + '&page=' + page
      return apiClient.get(relativeURL)
    },
}