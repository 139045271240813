import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_refund: 'api/v1/ipanel/refund/',
  url_action: 'api/v1/ipanel/refund-actions/'
}

export default {
  getRefundList (perPage, page, searchQuery) {
    var relativeURL = url_list.url_refund + '?page_size=' + perPage + '&page=' + page

    if (searchQuery) {
      relativeURL += '&search=' + searchQuery
    }
    return apiClient.get(relativeURL)
  },

  createRefund (data) {
    return apiClient.post(url_list.url_refund, data)
  },

  getRefundDetail (refund_id) {
    return apiClient.get(url_list.url_refund + refund_id)
  },

  changeRefundStatus (data) {
    return apiClient.patch(url_list.url_refund + data.refund_id + '/', data)
  },

  createAction (refund_id, data) {
    return apiClient.post(url_list.url_refund + refund_id + '/actions/', data)
  },

  removeAction (refund_id,action_id) {
    return apiClient.delete(url_list.url_refund + refund_id + '/actions/' + action_id + '/')
  },

  updateRefund(refund_id,data){
    return apiClient.put(url_list.url_refund + refund_id + '/', data)
  }
}