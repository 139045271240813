import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
    baseURL: process.env.BACKEND_HOST
})

const url_list = {
    tutor_payout_data: 'api/v1/ipanel/flip/tutor-payout-data/',
    tutor_payout_detail: 'api/v1/ipanel/flip/tutor-payout-detail/',

    referrer_payout_data: 'api/v1/ipanel/flip/referrer/referrer-payout-data/',
    referrer_payout_detail: 'api/v1/ipanel/flip/referrer/referrer-payout-detail/',

    tutor_payout_request: 'api/v1/ipanel/tutor-payout-request-admin/',
}

export default {
    getTutorPayoutList(page, perPage, searchQuery, filterYear, filterMonth) {
        var relativeURL = url_list.tutor_payout_data + `?page_size=${perPage}&page=${page}`
        if (filterYear && filterMonth) {
            relativeURL += `&year=${filterYear}&month=${filterMonth}`
        }
        if (searchQuery) {
            relativeURL += `&search=${searchQuery}`
        }
        return apiClient.get(relativeURL)
    },
    getTutorPayoutDetail(tutor_id) {
        var relativeURL = url_list.tutor_payout_detail + `?tutor_id=${tutor_id}`
        return apiClient.get(relativeURL)
    },

    getTutorPayoutRequestList(page, perPage, searchQuery) {
        var relativeURL = `${url_list.tutor_payout_request}?page_size=${perPage}&page=${page}`
        if (searchQuery) {
            relativeURL += `&search=${searchQuery}`
        }
        return apiClient.get(relativeURL)
    },
    getReferrerPayoutList(page, perPage, searchQuery) {
        var relativeURL = url_list.referrer_payout_data + `?page_size=${perPage}&page=${page}`
        if (searchQuery) {
            relativeURL += `&search=${searchQuery}`
        }
        return apiClient.get(relativeURL)
    },
    getReferrerPayoutDetail(referral_code) {
        var relativeURL = url_list.referrer_payout_detail + `?referral_code=${referral_code}`
        return apiClient.get(relativeURL)
    },
}