import Vue from 'vue'
import Router from 'vue-router'

// spa components
const DashboardV2 = ()=> import('../pages/instapanel/general/Dashboard.vue')
const PageIssueV2 = ()=> import('../pages/instapanel/general/PageIssue.vue')
const AnalyticsFinanceV2 = ()=> import('../pages/instapanel/general/AnalyticsFinance.vue')
const AnalyticsUnitecon = ()=> import('../pages/instapanel/general/AnalyticsUnitecon.vue')
const AnalyticsStudentV2 = ()=> import('../pages/instapanel/general/AnalyticsStudent.vue')
const AnalyticsTutorV2 = ()=> import('../pages/instapanel/general/AnalyticsTutor.vue')
const AnalyticsBlog = ()=> import('../pages/instapanel/general/AnalyticsBlog.vue')
const PageTutorManagementV2 = ()=> import('../pages/instapanel/managementv2/PageTutorManagement.vue')
const PageStudentManagementV2 = ()=> import('../pages/instapanel/managementv2/PageStudentManagement.vue')
const PageReferrerManagementV2 = ()=> import('../pages/instapanel/managementv2/PageReferrerManagement.vue')
const PageClassManagementV2 = ()=> import('../pages/instapanel/managementv2/PageClassManagement.vue')
const PageInvoiceManagementV2 = ()=> import('../pages/instapanel/managementv2/PageInvoiceManagement.vue')
const PageJobPostV2 = ()=> import('../pages/instapanel/app_managementv2/PageJobPost.vue')
const PageCertificateV2 = ()=> import('../pages/instapanel/utility_toolsv2/PageCertificate.vue')
const PageBackuplogsV2 = ()=> import('../pages/instapanel/utility_toolsv2/PageBackuplogs.vue')
const PageEmailLogsV2 = ()=> import('../pages/instapanel/utility_toolsv2/PageEmailLogs.vue')
const PageTutorAccountV2 = ()=> import('../pages/instapanel/utility_toolsv2/PageTutorAccount.vue')
const PageDiscountV2 = ()=> import('../pages/instapanel/utility_toolsv2/PageDiscount.vue')
const PageDiscountManagementUtilityV2 = ()=> import('../pages/instapanel/management_utilityv2/PageDiscount.vue')
const PageSubjectManagementUtilityV2 = ()=> import('../pages/instapanel/management_utilityv2/PageSubject.vue')
const PageGradeManagementUtilityV2 = ()=> import('../pages/instapanel/management_utilityv2/PageGrade.vue')
const PageCurriculamManagementUtilityV2 = ()=> import('../pages/instapanel/management_utilityv2/PageCurriculum.vue')
const PageExpense = ()=> import('../pages/instapanel/financev2/PageExpense.vue')
const PageTutorsPayout = ()=> import('../pages/instapanel/financev2/PageTutorsPayout.vue')
const PageReferrersPayout = ()=> import('../pages/instapanel/financev2/PageReferrersPayout.vue')
const PageFlip = ()=> import('../pages/instapanel/financev2/PageFlip.vue')
const PageFlipHistoryApi = ()=> import('../components/instapanel/finance/flip_payment/PageFlipHistoryApi.vue')
const PageFlipHistoryLocal = ()=> import('../components/instapanel/finance/flip_payment/PageFlipHistoryLocal.vue')
const PageReportV2 = ()=> import('../pages/instapanel/reportv2/PageReport.vue')
const PageCustomerV2 = ()=> import('../pages/instapanel/marketingv2/PageCustomer.vue')
const PagePartnerV2 = ()=> import('../pages/instapanel/marketingv2/PagePartner.vue')
const PageFlipTutorPayoutRequestV2 = ()=> import('../pages/instapanel/financev2/PageFlipTutorPayoutRequest.vue')
const PageFlipTutorPayoutV2 = ()=> import('../pages/instapanel/financev2/PageFlipTutorPayout.vue')
const PageFlipReferrerPayout = ()=> import('../pages/instapanel/financev2/PageFlipReferrerPayout.vue')
const PageRefund = ()=> import('../pages/instapanel/financev2/PageRefund.vue')
const PageTutorRegistration = ()=> import('../pages/home/PageTutorRegistration.vue')
const PageTutorJob = ()=> import('../pages/operation/PageTutorJob.vue')
const PageTutorJobApplication = ()=> import('../pages/operation/PageTutorJobApplication.vue')
const PageTutorJobDetail = ()=> import('../pages/operation/PageTutorJobDetail.vue')

const PageIndex = ()=> import('../pages/home/PageIndex.vue')
const PageLogin = ()=> import('../pages/home/PageLogin.vue')
const PageForgotPassword = ()=> import('../pages/home/PageForgotPassword.vue')

// Version 2 SPA Students
const PageTutorResumeList = ()=> import ('../pages/home/PageTutorResumeList.vue')
const PageViewTutorResume = ()=> import ('../pages/home/PageViewTutorResume.vue')
const PageStudentAgreement = ()=> import ('../pages/home/PageStudentAgreement.vue')

const PageFaq = ()=> import('../pages/home/PageFaq.vue')
const PageTeachingJobs = ()=> import('../pages/home/PageTeachingJobs.vue')


import PageBlogList from '../pages/blog/PageBlogList.vue'
import PageBlogCategory from '../pages/blog/PageBlogCategory.vue'
import PageBlogDetail from '../pages/blog/PageBlogDetail.vue'
import PageBlog from '../pages/blog/PageBlog.vue'
const PageCertificateVerification = ()=> import('../pages/home/PageCertificateVerification.vue')
const PageAdminUserManagement = ()=> import('../pages/instapanel/managementv2/PageAdminUserManagement.vue')

const NotFound = ()=> import('../components/NotFound.vue')
const PreventAdminLogout = ()=> import('../pages/instapanel/general/PreventAdminLogout.vue')
// New Marketing Panel routes
const PageManager = ()=> import('../pages/instapanel/general/PageManager.vue')

// Staff Panel
const PageSpanelAttendancePortal = ()=> import ('../pages/spanel/AttendancePortal.vue')
const PageSpanelTutorManagement = ()=> import ('../pages/spanel/TutorManagement.vue')
const PageSpanelTutorJobs = ()=> import ('../pages/spanel/TutorJobs.vue')
const PageSpanelBlogManagement = ()=> import ('../pages/spanel/BlogManagement.vue')
const PageSpanelClassManagement = ()=> import ('../pages/spanel/ClassManagement.vue')
const PageSpanelAppProgramManagement = ()=> import ('../pages/spanel/app/ProgramManagement.vue')
const PageSpanelAppPwnManagement = ()=> import ('../pages/spanel/app/PwnManagement.vue')
const PageSpanelAppInstalearnManagement = ()=> import ('../pages/spanel/app/InstalearnManagement.vue')
const PageSpanelAppBillingManagement = ()=> import ('../pages/spanel/app/BillingManagement.vue')
const PageSpanelAppSettingsManagement = ()=> import ('../pages/spanel/app/SettingsManagement.vue')
const PageSpanelPushNotification = ()=> import ('../pages/spanel/app/PushNotification.vue')

Vue.use(Router)

const routes = [
    { path: '/', component: PageIndex, name: 'page-index', pathToRegexpOptions: { strict: true }, meta: {"title": "InstaPrivat - Les Privat ke Rumah Paket Murah Se-Jabodetabek"}},
    { path: '/login/', component: PageLogin, name: 'page-login', pathToRegexpOptions: { strict: true }, meta: {title: "Login"} },
    { path: '/forgot-password/', component: PageForgotPassword, name: 'page-forgot-password', pathToRegexpOptions: { strict: true }, meta: {title: "Forgot Password"} },
    { path: '/tutor-registration/', component: PageTutorRegistration, name: 'page-tutor-registration', pathToRegexpOptions: { strict: true }, meta: {title: "InstaPrivat - Jadi Guru"} },
    { path: '/treg/', component: PageTutorRegistration, name: 'page-t-reg', pathToRegexpOptions: { strict: true }, meta: {title: "InstaPrivat - Jadi Guru"} },

    { path: '/ipanel/v2/admin-user/', component: PageAdminUserManagement, name: 'version2-management-admin-user', pathToRegexpOptions: { strict: true }},
    // BLOG APP: 
    { path: '/blog/', component: PageBlogList, name: 'version2-blog-list', pathToRegexpOptions: { strict: true }, meta: {title: "InstaPrivat - Blogs"} },
    { path: '/blog/category/:category_name/', component: PageBlogCategory, name: 'version2-blog-category', props: true, pathToRegexpOptions: { strict: true }, meta: {title: "Blog Category"} },
    { path: '/blog/:day/:month/:year/:slug/', component: PageBlogDetail, name: 'version2-blog-detail',  props: true, pathToRegexpOptions: { strict: true } },

    { path: '/siswa/agreement/:agreementId/', component: PageStudentAgreement, name: 'siswa-agreement', props: true, pathToRegexpOptions: { strict: true }, meta: {title: "Surat Perjanjian"} },
    // ipanel blog urls

    { path: '/ipanel/blog/', component: PageBlog, name: 'ipanel-blog', pathToRegexpOptions: { strict: true }, meta: {title: "Blogs"} },

    // App Management spa urls
    

    // general version 2 spa urls
    { path: '/ipanel/v2/dashboard/', component: DashboardV2, name: 'version2-dashboard', pathToRegexpOptions: { strict: true }, meta: {title: "Dashboard"} },
    { path: '/ipanel/v2/issues/', component: PageIssueV2, name: 'version2-issue', pathToRegexpOptions: { strict: true }, meta: {title: "Issues"} },
    { path: '/ipanel/v2/analytics/finance/', component: AnalyticsFinanceV2, name: 'version2-analytics-finance', pathToRegexpOptions: { strict: true }, meta: {title: "Finance Analytics"} },
    { path: '/ipanel/v2/analytics/unit-econ/', component: AnalyticsUnitecon, name: 'version2-analytics-unitecon', pathToRegexpOptions: { strict: true }, meta: {title: "Unit economics"} },
    { path: '/ipanel/v2/analytics/tutor/', component: AnalyticsTutorV2, name: 'version2-analytics-tutor', pathToRegexpOptions: { strict: true }, meta: {title: "Tutor Analytics"} },
    { path: '/ipanel/v2/analytics/student/', component: AnalyticsStudentV2, name: 'version2-analytics-student', pathToRegexpOptions: { strict: true }, meta: {title: "Student Analytics"} },
    { path: '/ipanel/v2/analytics/blog/', component: AnalyticsBlog, name: 'version2-analytics-blog', pathToRegexpOptions: { strict: true }, meta: {title: "Blog Analytics"} },
    { path: '/ipanel/v2/prevent-admin-logout/', component: PreventAdminLogout, name: 'prevent-admin-logout', pathToRegexpOptions: { strict: true }, meta: {title: "Prevent Admin Logout"} },
    { path: '/ipanel/v2/manager/', component: PageManager, name: 'ipanel-manager', pathToRegexpOptions: { strict: true }, meta: {title: "Manager"} },

    // management version 2 spa urls
    { path: '/ipanel/v2/tutor/', component: PageTutorManagementV2, name: 'version2-management-tutor', pathToRegexpOptions: { strict: true }, meta: {title: "Tutor"} },
    { path: '/ipanel/v2/student/', component: PageStudentManagementV2, name: 'version2-management-student', pathToRegexpOptions: { strict: true }, meta: {title: "Student"} },
    { path: '/ipanel/v2/referrers/', component: PageReferrerManagementV2, name: 'version2-management-referrer', pathToRegexpOptions: { strict: true }, meta: {title: "Referrer"} },
    { path: '/ipanel/v2/class/', component: PageClassManagementV2, name: 'version2-management-class', pathToRegexpOptions: { strict: true }, meta: {title: "Class"} },
    { path: '/ipanel/v2/invoice/', component: PageInvoiceManagementV2, name: 'version2-management-invoice', pathToRegexpOptions: { strict: true }, meta: {title: "Invoice"} },

    // app_management version 2 spa urls
    { path: '/ipanel/v2/job-post/', component: PageJobPostV2, name: 'version2-job-post', pathToRegexpOptions: { strict: true }, meta: {title: "Job Post"} },

    // management utility version2 spa urls
    { path: '/ipanel/v2/class/subjects/', component: PageSubjectManagementUtilityV2, name: 'version2-management-utility-subject', pathToRegexpOptions: { strict: true }, meta: {title: "Subject"} },
    { path: '/ipanel/v2/curriculums/', component: PageCurriculamManagementUtilityV2, name: 'version2-management-utility-curriculam', pathToRegexpOptions: { strict: true }, meta: {title: "Curriculum"} },
    { path: '/ipanel/v2/grades/', component: PageGradeManagementUtilityV2, name: 'version2-management-utility-grade', pathToRegexpOptions: { strict: true }, meta: {title: "Grade"} },
    { path: '/ipanel/v2/discounts/', component: PageDiscountManagementUtilityV2, name: 'version2-management-utility-discount', pathToRegexpOptions: { strict: true }, meta: {title: "Discount"} },

    // utility tools version 2 spa urls
    { path: '/ipanel/v2/utility-tools/certificate/', component: PageCertificateV2, name: 'version2-utility-tools-certificate', pathToRegexpOptions: { strict: true }, meta: {title: "Certificate"} },
    { path: '/ipanel/v2/utility-tools/backup-log/', component: PageBackuplogsV2, name: 'version2-utility-tools-backup-logs', pathToRegexpOptions: { strict: true }, meta: {title: "Backup Logs"} },
    { path: '/ipanel/v2/utility-tools/tutor-account/', component: PageTutorAccountV2, name: 'version2-utility-tools-tutor-account', pathToRegexpOptions: { strict: true }, meta: {title: "Tutor Account"} },
    { path: '/ipanel/v2/utility-tools/discount/', component: PageDiscountV2, name: 'version2-utility-tools-discount', pathToRegexpOptions: { strict: true }, meta: {title: "Discount"} },
    { path: '/ipanel/v2/utility-tools/email-log/', component: PageEmailLogsV2, name: 'version2-utility-tools-email-logs', pathToRegexpOptions: { strict: true }, meta: {title: "Email Logs"} },

    // finance version 2 spa urls
    { path: '/ipanel/v2/finance/expense/', component: PageExpense, name: 'version2-finance-expense', pathToRegexpOptions: { strict: true }, meta: {title: "Expense"} },
    { path: '/ipanel/v2/finance/tutors-payout/', component: PageTutorsPayout, name: 'version2-finance-tutors-payout', pathToRegexpOptions: { strict: true }, meta: {title: "Tutor's Payout"} },
    { path: '/ipanel/v2/finance/referrers-payout/', component: PageReferrersPayout, name: 'version2-finance-referrers-payout', pathToRegexpOptions: { strict: true }, meta: {title: "Referrer's Payout"} },
    { path: '/ipanel/v2/finance/flip/', component: PageFlip, name: 'version2-finance-flip', pathToRegexpOptions: { strict: true }, meta: {title: "Flip"} },
    { path: '/ipanel/v2/finance/flip/transactions/api/', component: PageFlipHistoryApi, name: 'version2-finance-flip-trx-api', pathToRegexpOptions: { strict: true }, meta: {title: "Flip Trx Api"} },
    { path: '/ipanel/v2/finance/flip/transactions/local/', component: PageFlipHistoryLocal, name: 'version2-finance-flip-trx-local', pathToRegexpOptions: { strict: true }, meta: {title: "Flip Trxs Local"} },
    { path: '/ipanel/v2/finance/payment/flip/tutor-payout-request/', component: PageFlipTutorPayoutRequestV2, name: 'version2-finance-flip-tutor-payout-request', pathToRegexpOptions: { strict: true }, meta: {title: "Flip Payout Request"} },
    { path: '/ipanel/v2/finance/payment/flip/tutor-payout/', component: PageFlipTutorPayoutV2, name: 'version2-finance-flip-tutor-payout', pathToRegexpOptions: { strict: true }, meta: {title: "Flip Tutor Payout"} },
    { path: '/ipanel/v2/finance/refund/', component: PageRefund, name: 'version2-finance-refund', pathToRegexpOptions: { strict: true }, meta: {title: "Refund"} },
    { path: '/ipanel/v2/finance/payment/flip/referrer-payout/', component: PageFlipReferrerPayout, name: 'version2-finance-flip-referrer-payout', pathToRegexpOptions: { strict: true }, meta: {title: "Flip Referrer Payout"} },
  
    // operation version 2 spa urls
    { path: '/ipanel/v2/operation/tutor-jobs/', component: PageTutorJob, name: 'version2-operation-tutor-jobs', pathToRegexpOptions: { strict: true }, meta: {title: "Tutor Jobs"} },
    { path: '/ipanel/v2/operation/tutor-jobs/:id/', component: PageTutorJobDetail, props: true, name: 'version2-operation-tutor-job-detail', pathToRegexpOptions: { strict: true }, meta: {title: "Tutor Job Detail"} },

    // report version 2 spa urls
    { path: '/ipanel/v2/report/', component: PageReportV2, name: 'version2-report', pathToRegexpOptions: { strict: true }, meta: {title: "Report"} },

    // marketing version 2 spa urls
    { path: '/ipanel/v2/marketing/customer/', component: PageCustomerV2, name: 'version2-marketing-customer', pathToRegexpOptions: { strict: true }, meta: {title: "Customers"} },
    { path: '/ipanel/v2/marketing/partner/', component: PagePartnerV2, name: 'version2-marketing-partner', pathToRegexpOptions: { strict: true }, meta: {title: "Partners"} },

    { path: '/tutor/resumes/', component: PageTutorResumeList, name: 'tutor-resume-list', pathToRegexpOptions: { strict: true }, meta: {title: "Our Tutors"} },
    { path: '/tutor/resume/:id/', component: PageViewTutorResume, name: 'tutor-resume-page', props: true, pathToRegexpOptions: { strict: true }, meta: {title: "Tutor Resume Details"} },

    { path: '/faq/', component: PageFaq, name: 'faq', props: true, pathToRegexpOptions: { strict: true }, meta: {title: "InstaPrivat - FAQ"} },
    { path: '/jobs/', component: PageTeachingJobs, name: 'teaching-jobs-1', props: true, pathToRegexpOptions: { strict: true }, meta: {title: "Teaching Jobs"} },
    { path: '/teaching-jobs/', component: PageTeachingJobs, name: 'teaching-jobs', props: true, pathToRegexpOptions: { strict: true }, meta: {title: "Teaching Jobs"} },
    { path: '/certificate-verification/', component: PageCertificateVerification, name: 'certificate-verification', pathToRegexOptions: { strict: true }, meta: {title: "Certificate Verification"} },
    { path: '/tutor-jobs/:uniqueId/', component: PageTutorJobApplication, name: 'page-tutor-job-application', props: true, pathToRegexpOptions: { strict: true }, meta: {title: "Tutor Job Application"} },

    // { path: '/verify-student-certificate/', component: PageStudentCertificateVerify, name: 'verify-student-certificate', pathToRegexOptions: { strict: true }, meta: {title: "Student Certificate Verification"} },
    // { path: '/verify-tutor-certificate/', component: PageTutorCertificateVerify, name: 'verify-tutor-certificate', pathToRegexOptions: { strict: true }, meta: {title: "Tutor Certificate Verification"} },
    // { path: '/404', component: NotFound },  
    // { path: '*', redirect: '/404' }

    // Staff Panel: Spanel
    { path: '/spanel/attendance/', component: PageSpanelAttendancePortal, name: 'spanel-attendance', pathToRegexpOptions: { strict: true }, meta: {title: "Attendance - Student/Tutor"} },
    { path: '/spanel/tutor-management/', component: PageSpanelTutorManagement, name: 'spanel-tutor-management', pathToRegexpOptions: { strict: true }, meta: {title: "Tutor Management"} },
    { path: '/spanel/tutor-jobs/', component: PageSpanelTutorJobs, name: 'spanel-tutor-jobs', pathToRegexpOptions: { strict: true }, meta: {title: "Tutor Jobs"} },
    { path: '/spanel/class-management/', component: PageSpanelClassManagement, name: 'spanel-class-management', pathToRegexpOptions: { strict: true }, meta: {title: "Class Management"} },
    { path: '/spanel/blog-management/', component: PageSpanelBlogManagement, name: 'spanel-blog-management', pathToRegexpOptions: { strict: true }, meta: {title: "Blog Management"} },

    { path: '/spanel/app/student/program/', component: PageSpanelAppProgramManagement, name: 'spanel-student-app-program', pathToRegexpOptions: { strict: true }, meta: {title: "Service Management"} },
    { path: '/spanel/app/student/pwn/', component: PageSpanelAppPwnManagement, name: 'spanel-student-app-pwn', pathToRegexpOptions: { strict: true }, meta: {title: "Promo, WN & News Management"} },
    { path: '/spanel/app/student/instalearn/', component: PageSpanelAppInstalearnManagement, name: 'spanel-student-app-instalearn', pathToRegexpOptions: { strict: true }, meta: {title: "InstaLearn"} },
    { path: '/spanel/app/student/billing/', component: PageSpanelAppBillingManagement, name: 'spanel-student-app-billing', pathToRegexpOptions: { strict: true }, meta: {title: "Billing"} },
    { path: '/spanel/app/settings/', component: PageSpanelAppSettingsManagement, name: 'spanel-student-app-settings', pathToRegexpOptions: { strict: true }, meta: {title: "Settings"} },
    { path: '/spanel/app/push-notification/', component: PageSpanelPushNotification, name: 'spanel-student-app-push-notification', pathToRegexpOptions: { strict: true }, meta: {title: "Push Notification"} },
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router