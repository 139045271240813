import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_post: 'api/v1/blog/posts/',
  url_category: '/api/v1/blog/categories/'
}

export default {
    getAllCategories(){
        return apiClient.get(url_list.url_category + 'get-all-categories/')
    },

    getPostStatusChoices(){
        return apiClient.get(url_list.url_post + 'get-post-status-choices/')
    },

    createPost(form){
        return apiClient.post(url_list.url_post, form)
    },

    updatePost(form, slug){
        return apiClient.patch(url_list.url_post + `${slug}/`, form)
    },

    deletePost(slug){
        return apiClient.delete(url_list.url_post + `${slug}/`)
    },

    getAllPosts(perPage, page, searchQuery){
        var relativeURL = url_list.url_post + '?page_size=' + perPage + '&page=' + page
        if (searchQuery) {
            relativeURL += '&search=' + searchQuery
          }
        return apiClient.get(relativeURL)
    },
}