<template>
    <div>
        <div class="container my-4" style="font-size:14px;">
            <b-row>
                <b-col v-if="postList.length>0" cols="12" md="9">
                    <div v-if="isSearching" class="bg-white shadow-sm p-4">
                        <h4>
                            Search results for {{searchQuery}}
                            <b-button @click="reset" variant="light">
                                <font-awesome-icon icon="times-circle" variant="danger" />
                            </b-button>
                        </h4>
                    </div>
                    <div v-for="post,index in postList" :key="index" class="bg-white shadow-sm p-4">
                        <b-row>
                            <b-col cols="10">
                                <b-link @click="goto(post)" class="text-ip" style="font-size: 20px;">
                                    {{post.title}}
                                </b-link>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <small><font-awesome-icon icon="eye" /> {{post.views}} </small> 
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mt-4 text-right">
                        <b-pagination-nav
                        :link-gen="linkGen"
                        :number-of-pages="totalPage"
                        @change="fetchPostList"
                        use-router
                        >
                        </b-pagination-nav>
                    </div>
                </b-col>
                <b-col v-else cols="12" md="9" class="card">
                    <div v-if="isSearching" class="bg-white shadow-sm p-4 mt-2">
                        <h4>
                            Search results for {{searchQuery}}
                            <b-button @click="reset" variant="light">
                                <font-awesome-icon icon="times-circle" variant="danger" />
                            </b-button>
                        </h4>
                    </div>
                    <div v-if="postList.length==0 && isProcessing==true">
                        <ContentLoader width="500" height="400">
                            <rect x="0" y="10" rx="5" ry="5" width="100%" height="80" />
                            <rect x="0" y="100" rx="5" ry="5" width="100%" height="80" />
                            <rect x="0" y="190" rx="5" ry="5" width="100%" height="80" />
                            <rect x="0" y="280" rx="5" ry="5" width="100%" height="80" />
                        </ContentLoader>
                    </div>
                    <div v-if="postList.length==0 && isProcessing==false" 
                    class="bg-white shadow-sm p-4 mt-2">
                        <h4>No post available</h4>
                    </div>
                </b-col>
                <b-col cols="12" md="3">
                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="searchPost">
                                <div class="input-group input-full-width">
                                    <b-input
                                    v-model="searchQuery"
                                    type="text"
                                    placeholder="Search Here"
                                    ></b-input>
                                    <div class="input-group-append">
                                        <b-button type="submit" variant="primary" class="w-30">
                                            <b>Search</b>
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                        </b-col>
                        <b-col class="bg-white shadow-sm p-4 mx-3 mt-2">
                            <span class="mt-2"><h6><b>Categories</b></h6></span>
                            <span>
                                <b-link :to="{name: 'version2-blog-list'}" @click="reset" class="text-ip">
                                    All, 
                                </b-link>
                            </span>
                            <span v-for="category,index in category_list" :key="index">
                                <b-link @click="gotoCategory(category.text)" class="text-ip">
                                    {{category.text}}
                                </b-link>
                                <span v-if="category_list.length-1!=index">, </span>
                            </span>
                        </b-col>
                        <b-col v-if="latestPostList.length>0" class="mt-4" cols="12">
                            <span ><h6><b>Latest 6 Articles</b></h6></span>
                        </b-col>
                        <b-col class="bg-white shadow-sm p-4 mx-3">
                            <span v-for="post,index in latestPostList" :key="index">
                                <b-link @click="goto(post)" class="text-ip">
                                    {{post.title}}
                                </b-link>
                                <span v-if="latestPostList.length-1!=index"><hr> </span>
                            </span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { ContentLoader } from "vue-content-loader"
import { mapState } from 'vuex';

export default {
  components: {
      ContentLoader
  },
  computed: {
    ...mapState(["blog"]),
    category_list(){
        if (this.blog.categoryList) 
            return this.blog.categoryList
        else []
    },
    totalPage() {
      if (this.postCount % this.perPage !== 0) {
        return this.postCount / this.perPage + 1;
      }
      if (this.postCount / this.perPage == 0) {
        return 1;
      }
      return this.postCount / this.perPage;
    },
  },

  created() {
    if (this.$route.query.search ){
      this.searchQuery = this.$route.query.search
      this.searchPost()
      this.fetchLatestPostList()
    }
    else this.fetchPostList()
    this.fetchCategoryList()
  },

  data() {
    return {
      postList: [],
      postCount: null,
      perPage: 20,
      searchQuery: null,
      isSearching: false,
      latestPostList: [],
      isProcessing: false,
    };
  },

  methods: {
    reset() {
        this.isSearching = false
        this.searchQuery = null
        this.fetchPostList()
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    goto(post){
        const parts = post.date.split("-");
        var day = parseInt(parts[parts.length-1]) 
        var month = parseInt(parts[parts.length-2])
        var year = parseInt(parts[parts.length-3])
        this.$router.push({ 
            name: 'version2-blog-detail', 
            params: { day: day, month: month, year: year, slug: post.slug} 
        })
    },
    fetchLatestPostList(){
        axios.get(`/api/v1/blog/posts/?page_size=6&page=${this.$route.query.page || 1}`)
        .then(resp=>{
            if (resp.status==200) {
                this.latestPostList = resp.data.results
            }
        })
    },
    fetchPostList() {
        this.latestPostList = []
        if (this.isSearching==false){
            this.isProcessing = true
            axios.get(`/api/v1/blog/posts/?page_size=${this.perPage}&page=${this.$route.query.page || 1}`)
            .then(resp=>{
                this.isProcessing = false
                if (resp.status==200) {
                    this.postList = resp.data.results
                    this.postCount = resp.data.count
                    for (let i=0; i<resp.data.results.length; i++){
                        if (i<6) this.latestPostList.push(resp.data.results[i])
                    }
                }
            })
            .catch(error=>{
                this.isProcessing = false
            })
        }
        else this.searchPost()
    },
    gotoCategory(title){
        this.$router.push({ 
            name: 'version2-blog-category', 
            params: { category_name: title}
        })
    },
    searchPost(){
        if (this.searchQuery) {
            this.isProcessing = true
            axios.get(`/api/v1/blog/posts/?search=${this.searchQuery}&page_size=${this.perPage}&page=${this.$route.query.page || 1}`)
            .then(resp=>{
                this.isProcessing = false
                if (resp.status==200) {
                    this.postList = resp.data.results
                    this.postCount = resp.data.count
                    this.isSearching = true
                }
            })
            .catch(error=>{
                this.isProcessing = false
            })
        }
    },
    fetchCategoryList() {
        this.$store.dispatch('blog/getAllCategories')
    }
  },
};
</script>

<style scoped>
.text-ip {
  color: #155974 !important;
}
</style>
