import ServiceRefund from '../../services/ipanel/ServiceRefund.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const namespaced = true

export const state = {
    refundList: [],
    refundCount: null,
    refundDetail:null,

    editor: ClassicEditor,
    editorConfig:{
      toolbar: {
          items: [
              "bold",
              "italic",
              "BulletedList",
              "NumberedList",
              "undo",
              "redo"
          ]
      }
    },

}

export const mutations = {
    SET_REFUND_LIST (state, refunds) {
        state.refundList = refunds
    },

    SET_REFUND_COUNT(state, refunds_count){
      state.refundCount = refunds_count.length;
    },

    ADD_REFUND_TO_REFUND_LIST(state,refund){
      state.refundList.unshift(refund)
    },

    ADD_ACTION_TO_REFUND (state, action) {
      state.refundDetail.actions.unshift(action)
    },

    SET_REFUND_DETAIL(state,refund){
      state.refundDetail = refund
    },

    REMOVE_ACTION_FROM_REFUND_DETAIL(state,action_id){
      state.refundDetail.actions = state.refundDetail.actions.filter( action => {
        if (action.id != action_id){
          return action
        }
      });
    },

    CHANGE_REFUND_STATUS(state, refundInstance){
      state.refundList = state.refundList.filter( refund => {
        if(refund.id ==  refundInstance.id){
          refund.status = refundInstance.status
          refund.status_display = refundInstance.status_display
        }
        return refund
      })
    },

    UPDATE_REFUND_DETAIL(state, data){
      state.refundDetail = data
    },

    UPDATE_REFUND_LIST(state,refundInstance){
      state.refundList = state.refundList.filter( refund => {
        if(refund.id ==  refundInstance.id){
          refund.reason_of_refund = refundInstance.reason_of_refund
        }
        return refund
      })
    },

}

export const actions = {
    getRefundList({ commit }, {perPage, page, searchQuery}) {
        ServiceRefund.getRefundList(perPage, page, searchQuery)
          .then(resp => {
            commit('SET_REFUND_LIST', resp.data)
            commit('SET_REFUND_COUNT', resp.data)
          })
          .catch(err => {
            console.log(err.message)
          })
      },

    createRefund({ commit }, data){
        return ServiceRefund.createRefund(data)
        .then( response => {
          if(response.status==201){
            commit('ADD_REFUND_TO_REFUND_LIST', response.data)
            // For Showing Success Notification
            const vm = new Vue()
            vm.$bvToast.toast(`Refund created Successfully.`, {
              toaster: 'b-toaster-top-right',
              noCloseButton: true,
              bodyClass: 'bg-info',
              autoHideDelay: 5000
            })
            return response
          }
        })
        .catch(error => {
          console.log(error.message)
        })
    },

    getRefundDetail ({ commit, getters }, refund_id) {
      var refund = getters.getRefundFromGetter(refund_id)
      if(refund){
        commit('SET_REFUND_DETAIL', refund)
      }
      else {
        ServiceRefund.getRefundDetail(refund_id)
        .then(resp => {
          if (resp.status == 200) {
            commit('SET_REFUND_DETAIL', resp.data)
          }
        })
        .catch(err => {
          console.log(err.message)
        })
      }
    },

    createAction ({commit}, { id, action }) {
      ServiceRefund.createAction(id, action)
        .then(resp => {
          if (resp.status == 201) {
            commit('ADD_ACTION_TO_REFUND', resp.data)
          }
        })
        .catch(err => {
          console.log(err.message)
        })
    },

    removeAction({ commit }, {refund_id, action_id } ){
      ServiceRefund.removeAction(refund_id, action_id)
      .then( resp => {
        if(resp.status == 204){
          commit('REMOVE_ACTION_FROM_REFUND_DETAIL', action_id)
        }
      })
    },

    changeRefundStatus({ commit }, refund_id,status ){
      ServiceRefund.changeRefundStatus(refund_id, status)
      .then(resp => {
        if(resp.status == 200){
          commit('CHANGE_REFUND_STATUS', resp.data)
        }
      })
    },

    updateRefund({ commit}, { refund_id, data }){
      return ServiceRefund.updateRefund(refund_id,data)
      .then(resp => {
        if(resp.status == 200){
          commit('UPDATE_REFUND_DETAIL', resp.data)
          commit('UPDATE_REFUND_LIST', resp.data)
          return resp
        }
      })
    }
}

export const getters = {
  getRefundFromGetter: (state) => (id) => {
    return state.refundList.find(obj => obj.id === id)
  }
}