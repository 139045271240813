import ServiceUser from '../services/ServiceUser.js'

export const namespaced = true

export const state = {
    userInfo: {},
    permissions: [],
    permissionGroup: [],
    groupPermissions: [],
    allPermissions: [],
    isSuperUser: false,
}

export const mutations = {
    SET_USER_INFO(state, info) {
        state.userInfo = info
    },
    SET_PERMISSION_GROUP(state, groups) {
        state.permissionGroup = groups
    },
    SET_PERMISSIONS(state, permisisons) {
        state.permissions = permisisons
    },
    SET_GROUP_PERMISSIONS(state, permisisons) {
        state.groupPermissions = permisisons
    },
    SET_ALL_PERMISSIONS(state, permisisons) {
        state.allPermissions = permisisons
    },
    SET_SUPERUSER(state, superuser) {
        state.isSuperUser = superuser
    },
}

export const actions = {
    getUserInfo({commit}) {
        ServiceUser.getUserInfo()
            .then(resp => {
                if (resp.status==200){
                    commit('SET_USER_INFO', resp.data.data)
                    commit('SET_SUPERUSER', resp.data.isSuperUser)
                    commit('SET_PERMISSION_GROUP', resp.data.groups)
                    commit('SET_PERMISSIONS', resp.data.permissions)
                    commit('SET_GROUP_PERMISSIONS', resp.data.groupPermissions)
                    commit('SET_ALL_PERMISSIONS', resp.data.allPermissions)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
}

export const getters = {}