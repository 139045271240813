import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_promotion: 'api/v1/ipanel/promotion/',
}


export default {
    getPromotionList(perPage, page, searchQuery) {
        var relativeURL = url_list.url_promotion + '?page_size=' + perPage + '&page=' + page
        if (searchQuery) {
            relativeURL += '&search=' + searchQuery
        }
        return apiClient.get(relativeURL)
    },
    createPromotion(data) {
        return apiClient.post(url_list.url_promotion, data)
    },
    updatePromotion(form_data) {
        return apiClient.patch(url_list.url_promotion + form_data.id + '/', form_data.data)
    },
    deletePromotion(id){
        return apiClient.delete(url_list.url_promotion + id)
    },
}