import ServiceTutor from '../../services/ipanel/ServiceTutor.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ServiceAuth from '../../services/ServiceAuth.js';

export const namespaced = true

export const state = {
    tutorList: [],
    tutorCount: null,
    totalActiveTutor: null,
    totalInactiveTutor: null,
    totalBannedTutor: null,
    tutorDetail: null,
    bankNameList: null,
    tutorStatistic: [],
    constanceSchedule: null,
    constanceScheduleWithoutPermission: null,
    cpanelSchedule: null,
    trackListCount: [],
    trackList: [],
    reputationCount: [],
    reputationList: [],
    allTutorsWithTutorId: [],
    allTutorsWithDjangoId: [],

    editor: ClassicEditor,
    editorConfig: {
        toolbar: {
            items: [
                "bold",
                "italic",
                "BulletedList",
                "NumberedList",
                "undo",
                "redo"
            ]
        }
    }
}

export const mutations = {
    SET_TUTOR_LIST(state, tutors) {
        state.tutorList = tutors
    },
    SET_TRACK_LIST(state, tracks) {
        state.trackList = tracks
    },
    SET_TRACK_LIST_COUNT(state, count) {
        state.trackListCount = count
    },
    SET_REPUTATION_LIST(state, reputations) {
        state.reputationList = reputations
    },
    SET_REPUTATION_COUNT(state, count) {
        state.reputationCount = count
    },
    SET_CONSTANCE_SCHEDULE(state, schedule) {
        state.constanceSchedule = schedule
    },
    SET_CONSTANCE_SCHEDULE_WITHOUT_PERMISSION(state, schedule) {
        state.constanceScheduleWithoutPermission = schedule
    },
    SET_CPANEL_SCHEDULE(state, schedule) {
        state.cpanelSchedule = schedule
    },
    UNSET_TUTOR_LIST(state) {
        state.tutorList = []
    },
    SET_TUTOR_COUNT(state, data) {
        state.tutorCount = data.count
        state.totalActiveTutor = 0
        state.totalInactiveTutor = 0
        state.totalBannedTutor = 0
        data.tutor_list.filter(tutor => {
            if (tutor.status == 1) {
                state.totalActiveTutor += 1
            }
            if (tutor.status == 2) {
                state.totalInactiveTutor += 1
            }
            if (tutor.status == 3) {
                state.totalBannedTutor += 1
            }
        })

    },
    UNSET_TUTOR_COUNT(state) {
        state.tutorCount = null
    },
    SET_TUTOR_DETAIL(state, tutor) {
        state.tutorDetail = tutor
    },
    UNSET_TUTOR_DETAIL(state) {
        state.tutorDetail = null
    },
    ADD_TUTOR(state, tutor) {
        state.tutorList.unshift(tutor)
    },
    SET_UPDATED_TUTOR(state, data) {
        const tutor_id = data.id
        state.tutorList = state.tutorList.filter(tutor => {
            if (tutor.id == tutor_id) {
                Object.assign(tutor, data);
            }
            return tutor
        });
    },
    UPDATE_TUTOR_DETAIL(state, data) {
        state.tutorDetail = data
    },
    SET_BANK_NAME_LIST(state, data) {
        state.bankNameList = data
    },
    ADD_NOTE(state, note) {
        state.tutorDetail.notes.unshift(note)
    },
    REMOVE_NOTE(state, note_id) {
        state.tutorDetail.notes = state.tutorDetail.notes.filter(note => {
            if (note.id != note_id) {
                return note
            }
        });
    },
    SET_TUTOT_STAISTIC(state, data) {
        state.tutorStatistic = data
    },
    SET_ALL_TUTORS_WITH_TUTORID(state, data) {
        state.allTutorsWithTutorId = []
        data.forEach(element=>{
            state.allTutorsWithTutorId.push({ id: element.tutor_id, text: element.name + " ("+ element.tutor_id + ")" })
        })
    },
    SET_ALL_TUTORS_WITH_DJANGO_ID(state, data){
        state.allTutorsWithDjangoId = []
        data.forEach(element=>{
            state.allTutorsWithDjangoId.push({ id: element.id, text: element.name + " ("+ element.tutor_id + ")" })
        })
    }
}

export const actions = {
    getTutorList({
        commit
    }, {
        perPage,
        page,
        searchQuery,
        gender,
        subjects_to_teach,
        teaching_areas,
        curriculum,
        religion,
        cities
    }) {
        ServiceTutor.getTutorList(perPage, page, searchQuery, gender, subjects_to_teach, teaching_areas, curriculum, religion, cities)
            .then(resp => {
                commit('SET_TUTOR_LIST', resp.data.results)
                commit('SET_TUTOR_COUNT', {count: resp.data.count, tutor_list: resp.data.results})
            })
            .catch(err => {
                console.log(err)
            })
    },
    getTrackList({
        commit
    }, {
        tutor_id,
        perPage,
        page
    }) {
        ServiceTutor.getTrackList(tutor_id, perPage, page)
            .then(resp => {
                commit('SET_TRACK_LIST', resp.data.results)
                commit('SET_TRACK_LIST_COUNT', resp.data.count)
            })
            .catch(err => {
                console.log(err)
            })
    },
    getReputationList({ commit }, {tutor_id, perPage, page}) {
        ServiceTutor.getReputationList(tutor_id, perPage, page)
            .then(resp => {
                commit('SET_REPUTATION_LIST', resp.data.results)
                commit('SET_REPUTATION_COUNT', resp.data.count)
            })
            .catch(err => {
                console.log(err)
            })
    },
    getConstanceSchedule({commit}) {
        ServiceTutor.getConstanceSchedule()
            .then(resp => {
                commit('SET_CONSTANCE_SCHEDULE', resp.data.tutor_schedule)
            })
            .catch(err => {
                console.log(err)
            })
    },
    getConstanceScheduleWithoutPermission({commit}) {
        ServiceTutor.getConstanceScheduleWithoutPermission()
            .then(resp => {
                commit('SET_CONSTANCE_SCHEDULE_WITHOUT_PERMISSION', resp.data.tutor_schedule)
            })
            .catch(err => {
                console.log(err)
            })
    },
    getCpanelSchedule({commit}) {
        ServiceTutor.getCpanelSchedule()
            .then(resp => {
                commit('SET_CPANEL_SCHEDULE', resp.data.schedule_availability)
            })
            .catch(err => {
                console.log(err)
            })
    },

    createTutor({
        commit
    }, data) {
        return ServiceTutor.createTutor(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_TUTOR', resp.data)
                    const vm = new Vue()
                    vm.$bvToast.toast(`Tutor created Successfully.`, {
                        toaster: 'b-toaster-bottom-right',
                        noCloseButton: true,
                        bodyClass: 'bg-info',
                        autoHideDelay: 2000
                    })
                    return resp
                }
            })
            .catch(err => {
                console.log(err)
            })
    },

    getTutorDetail({
        commit
    }, tutor_id) {
        return ServiceTutor.getTutorDetail(tutor_id)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_TUTOR_DETAIL', resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    updateTutor({
        commit
    }, {
        tutor_id,
        data
    }) {
        return ServiceTutor.updateTutor({
                tutor_id,
                data
            })
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_UPDATED_TUTOR', resp.data)
                    commit('UPDATE_TUTOR_DETAIL', resp.data)
                }
                Vue.toasted.success('Tutor Updated Successfully.');
                return resp
            })
            .catch(err => {
                console.log(err)
            })
    },
    getBankNameList({
        commit
    }) {
        return ServiceTutor.getBankNameList()
            .then(resp => {
                console.log(resp.data)
                if (resp.status == 200) {
                    commit('SET_BANK_NAME_LIST', resp.data.bank_name_list)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },

    createTutorNotes({
        commit
    }, data) {
        ServiceTutor.createTutorNotes(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_NOTE', resp.data)
                    Vue.toasted.success('Notes Created Successfully.');
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    deleteTutorNotes({
        commit
    }, {
        tutor_id,
        id
    }) {
        return ServiceTutor.deleteTutorNotes(tutor_id, id)
            .then(resp => {
                if (resp.status == 204) {
                    commit('REMOVE_NOTE', id)
                    Vue.toasted.error('Notes removed Successfully.');
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    getTutorStatistic({
        commit
    }, tutor_id) {
        ServiceTutor.getTutorStatistic(tutor_id)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_TUTOT_STAISTIC', resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    changeDefaultPassword({commit}, data) {
        return ServiceAuth.changeDefaultPassword(data)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_UPDATED_TUTOR', resp.data)
                    commit('UPDATE_TUTOR_DETAIL', resp.data)
                }
                Vue.toasted.success('Password Changed');
                return resp
            })
            .catch(err => {
                console.log(err)
            })
    },
    getAllTutors({commit}){
        ServiceTutor.getAllTutors()
        .then(response=>{
            commit('SET_ALL_TUTORS_WITH_TUTORID', response.data)
            commit('SET_ALL_TUTORS_WITH_DJANGO_ID', response.data)
        })
    }
}

export const getters = {}