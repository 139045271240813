import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_certificate: 'api/v1/ipanel/certificates/',
  url_certificate_tutor: 'api/v1/ipanel/certificates-tutor/',

}

export default {
  getCertificateList (perPage, page, searchQuery) {
      var relativeURL = url_list.url_certificate + '?page_size=' + perPage + '&page=' + page

      if (searchQuery) {
        var relativeURL = url_list.url_certificate + '?search=' + searchQuery
      }
      return apiClient.get(relativeURL)
  },
  createCertificate(data){
      return apiClient.post(url_list.url_certificate, data)
  },


  // Service For Tutor Certificate
  getTutorCertificateList (perPage, page, searchQuery) {
    var relativeURL = url_list.url_certificate_tutor + '?page_size=' + perPage + '&page=' + page

    if (searchQuery) {
      var relativeURL = url_list.url_certificate_tutor + '?search=' + searchQuery
    }
    return apiClient.get(relativeURL)
  },
  createTutorCertificate(data){
    return apiClient.post(url_list.url_certificate_tutor, data)
  },

}