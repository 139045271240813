import ServiceTutorJob from '../../services/operation/ServiceTutorJob.js'

export const namespaced = true

export const state = {
    tutorJobList: [],
    totalTutorJob: null,
}

export const mutations = {
    SET_JOB_LIST(state, jobs) {
        state.tutorJobList = jobs
    },
    SET_JOB_COUNT(state, count) {
        state.totalTutorJob = count
    },
    ADD_JOB(state, job) {
        state.tutorJobList.unshift(job)
    },
    UPDATE_JOB (state, targetJob) {
        state.tutorJobList.filter(job => {
            if (job.unique_code == targetJob.unique_code) {
                Object.assign(job, targetJob)
            }
        })
    }
}

export const actions = {
    getJobList({commit}, {perPage, page, searchQuery}) {
        ServiceTutorJob.getJobList({perPage, page, searchQuery})
            .then(resp => {
                if (resp.status==200){
                    commit('SET_JOB_LIST', resp.data.results)
                    commit('SET_JOB_COUNT', resp.data.count)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    createTutorJob({
        commit
    }, data) {
        return ServiceTutorJob.createTutorJob(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_JOB', resp.data)
                    Vue.toasted.success('Tutor Job created Successfully.');
                    return resp
                }
            })
            .catch(err => {
                if (err.response.data.unique_code != undefined) {
                    Vue.toasted.error(err.response.data.unique_code[0]);
                } else if (err.response.data[0] != undefined) {
                    Vue.toasted.error(err.response.data[0]);
                }
            })
    },
    updateTutorJob({
        commit
    }, data) {
        return ServiceTutorJob.updateTutorJob(data)
            .then(resp => {
                console.log(resp)
                if (resp.status == 200) {
                    commit('UPDATE_JOB', resp.data)
                    Vue.toasted.success('Tutor Job updated Successfully.');
                    return resp
                }
            })
            .catch(err => {
                Vue.toasted.error(err.response.data.unique_code[0]);
            })
    },
}

export const getters = {
    getTutorJobById: (state) => (unique_code) => {
        return state.tutorJobList.find(tutorJob => tutorJob.unique_code == unique_code)
    }
}
