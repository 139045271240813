<template>
    <div>
        <b-row>
            <b-col>
                <h1>POST</h1>
            </b-col>
        </b-row>
        <hr class="mt-0 pt-0">

        <b-row>
            <b-col cols="12">
                <div class="text-right mb-2">
                    <b-button
                    variant="primary"
                    size="md"
                    v-b-modal.modal_create_post>
                        <b>CREATE POST</b>
                    </b-button>
                </div>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12">
                <table-post></table-post>
            </b-col>
        </b-row>
        <modal-create-post 
        @postCreated="fetchAllPosts"></modal-create-post>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
const ModalCreatePost = ()=> import('../../components/instapanel/blog/post/ModalCreatePost.vue')
const TablePost = ()=> import('../../components/instapanel/blog/post/TablePost.vue')

export default {
    data(){
        return {
            perPage: 10
        }
    },
    components: {
        ModalCreatePost,
        TablePost
    },
    methods: {
        ...mapActions("blog", ["getAllPosts"]),
        fetchAllPosts(){
            this.getAllPosts(
                {
                    perPage: this.perPage,
                    page: this.$route.query.page || 1,
                    searchQuery: null
                }
            )
        }
    },
}
</script>

<style scoped>

</style>