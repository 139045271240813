import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_issues: 'api/v1/ipanel/issues/',
  url_action: 'api/v1/ipanel/issue-actions/'
}

export default {
  getIssueList (perPage, page, searchQuery) {
    var relativeURL = url_list.url_issues + '?page_size=' + perPage + '&page=' + page

    if (searchQuery) {
      relativeURL += '&search=' + searchQuery
    }
    return apiClient.get(relativeURL)
  },

  getCreateIssueData () {
    return apiClient.get(url_list.url_issues + 'create-issue-data/' )
  },

  createIssue (data) {
    return apiClient.post(url_list.url_issues, data)
  },

  getIssueDetail (issue_id) {
    return apiClient.get(url_list.url_issues + `${issue_id}` + '/')
  },

  changeIssueStatus (data) {
    return apiClient.patch(url_list.url_issues + data.issue_id + '/', data)
  },

  createAction (data) {
    return apiClient.post(url_list.url_action, data)
  },
  removeAction (action_id) {
    return apiClient.delete(url_list.url_action + action_id + '/')
  },
}