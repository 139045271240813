import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_referrer: 'api/v1/ipanel/referrers/',
}

export default {
  getReferrerList(perPage, page, searchQuery) {
    var relativeURL = url_list.url_referrer + '?page_size=' + perPage + '&page=' + page

    if (searchQuery) {
      relativeURL += '&search=' + searchQuery
    }
    return apiClient.get(relativeURL)
  },
  creatReferrer(data) {
    return apiClient.post(url_list.url_referrer, data)
  },
  getReferrerDetail(referrer_id) {
    return apiClient.get(url_list.url_referrer + referrer_id + '/')
  }
}