import ServiceDiscount from '../../services/ipanel/ServiceDiscount.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const namespaced = true

export const state = {
    discountList: [],
    discountCount: null,
    discountDetail:null,

    editor: ClassicEditor,
    editorConfig:{
      toolbar: {
          items: [
              "bold",
              "italic",
              "BulletedList",
              "NumberedList",
              "undo",
              "redo"
          ]
      }
    }
    
}

export const mutations = {
    SET_DISCOUNT_LIST(state, discounts) {
        state.discountList = discounts
    },
    UNSET_DISCOUNT_LIST(state) {
        state.discountList = []
    },
    SET_DISCOUNT_COUNT(state, count) {
        state.discountCount = count
    },
    UNSET_DISCOUNT_COUNT(state) {
        state.discountCount = null
    },
    SET_DISCOUNT_DETAIL(state, discount) {
        state.discountDetail = discount
    },
    UNSET_DISCOUNT_DETAIL(state) {
        state.discountDetail = null
    },
    ADD_DISCOUNT(state, discount) {
        state.discountList.unshift(discount)
    },
    SET_UPDATED_DISCOUNT(state, data){
        const discount_id = data.id
        state.discountList = state.discountList.filter(discount => {
            if(discount.id == discount_id){
                Object.assign(discount, data);
            }
            return discount
        });
    },
    UPDATE_DISCOUNT_DETAIL(state, data){
        state.discountDetail = data
    },
}

export const actions = {
    getDiscountList({
        commit
    }, {
        perPage,
        page,
        searchQuery
    }) {
        ServiceDiscount.getDiscountList(perPage, page, searchQuery)
            .then(resp => {
                commit('SET_DISCOUNT_LIST', resp.data.results)
                commit('SET_DISCOUNT_COUNT', resp.data.count)
            })
            .catch(err => {
                console.log(err)
            })
    },

    createDiscount({
        commit
    }, data) {
        ServiceDiscount.createDiscount(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_DISCOUNT', resp.data)
                    Vue.toasted.success('Discount created Successfully');
                }
            })
            .catch(err => {
                console.log(err)
            })
    },

    getDiscountDetail({ commit }, discount_id) {
        return ServiceDiscount.getDiscountDetail(discount_id)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_DISCOUNT_DETAIL', resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    updateDiscount({  commit }, { discount_id, data }) {
        return ServiceDiscount.updateDiscount({
            discount_id,
            data
        })
        .then(resp => {
            if (resp.status == 200) {
                commit('SET_UPDATED_DISCOUNT', resp.data)
                commit('UPDATE_DISCOUNT_DETAIL', resp.data)
            }
            Vue.toasted.success('discount Updated Successfully.');
        })
        .catch(err => {
            console.log(err)
        })
    },
}

export const getters = {}