import ServiceGrade from '../../services/ipanel/ServiceGrade.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const namespaced = true

export const state = {
    gradeList: [],
    gradeCount: null,
    gradeDetail:null,

    editor: ClassicEditor,
    editorConfig:{
      toolbar: {
          items: [
              "bold",
              "italic",
              "BulletedList",
              "NumberedList",
              "undo",
              "redo"
          ]
      }
    }
    
}

export const mutations = {
    SET_GRADE_LIST(state, grades) {
        state.gradeList = grades
    },
    UNSET_GRADE_LIST(state) {
        state.gradeList = []
    },
    SET_GRADE_COUNT(state, count) {
        state.gradeCount = count
    },
    UNSET_GRADE_COUNT(state) {
        state.gradeCount = null
    },
    SET_GRADE_DETAIL(state, grade) {
        state.gradeDetail = grade
    },
    UNSET_GRADE_DETAIL(state) {
        state.gradeDetail = null
    },
    ADD_GRADE(state, grade) {
        state.gradeList.unshift(grade)
    },
    SET_UPDATED_GRADE(state, data){
        const grade_id = data.id
        state.gradeList = state.gradeList.filter(grade => {
            if(grade.id == grade_id){
                Object.assign(grade, data);
            }
            return grade
        });
    },
    UPDATE_GRADE_DETAIL(state, data){
        state.gradeDetail = data
    },
}

export const actions = {
    getGradeList({
        commit
    }, {
        perPage,
        page,
        searchQuery
    }) {
        ServiceGrade.getGradeList(perPage, page, searchQuery)
            .then(resp => {
                commit('SET_GRADE_LIST', resp.data.results)
                commit('SET_GRADE_COUNT', resp.data.count)
            })
            .catch(err => {
                console.log(err)
            })
    },

    createGrade({
        commit
    }, data) {
        ServiceGrade.createGrade(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_GRADE', resp.data)
                    Vue.toasted.success('grade created Successfully');
                }
            })
            .catch(err => {
                console.log(err)
            })
    },

    getGradeDetail({ commit }, grade_id) {
        return ServiceGrade.getGradeDetail(grade_id)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_GRADE_DETAIL', resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    updateGrade({  commit }, { grade_id, data }) {
        return ServiceGrade.updateGrade({
            grade_id,
            data
        })
        .then(resp => {
            if (resp.status == 200) {
                commit('SET_UPDATED_GRADE', resp.data)
                commit('UPDATE_GRADE_DETAIL', resp.data)
            }
            Vue.toasted.success('grade Updated Successfully.');
        })
        .catch(err => {
            console.log(err)
        })
    },
}

export const getters = {}