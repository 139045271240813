import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_subject: 'api/v1/ipanel/subjects/',
}

export default {
  getSubjectList(perPage, page, searchQuery) {
      var relativeURL = url_list.url_subject + '?page_size=' + perPage + '&page=' + page

      if (searchQuery) {
        relativeURL += '&search=' + searchQuery
      }
      return apiClient.get(relativeURL)
    },
    createSubject(data) {
      return apiClient.post(url_list.url_subject, data)
    },
    getSubjectDetail(subject_id) {
      return apiClient.get(url_list.url_subject + subject_id + '/')
    },
    updateSubject(form_data) {
      return apiClient.patch(url_list.url_subject + form_data.subject_id + '/', form_data.data)
    },
    
}