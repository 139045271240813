import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_jobpost: 'api/v1/ipanel/job-posts/',
}


export default {
    getJobpostList(perPage, page, searchQuery) {
        var relativeURL = url_list.url_jobpost + '?page_size=' + perPage + '&page=' + page
        if (searchQuery) {
            relativeURL += '&search=' + searchQuery
        }
        return apiClient.get(relativeURL)
    },
    createJobpost(data) {
        return apiClient.post(url_list.url_jobpost, data)
    },
    updateJobpost(form_data) {
        return apiClient.patch(url_list.url_jobpost + form_data.id + '/', form_data.data)
    },
    deleteJobpost(id){
        return apiClient.delete(url_list.url_jobpost + id)
    },
}