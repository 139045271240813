import ServiceIssue from '../../services/ipanel/ServiceIssue.js'

export const namespaced = true

export const state = {
  issueList: [],
  issueCount: null,
  
  issueDetail: null,

  assignee_list: [],
  category_list: [],
  priority_list: [],
  todays_date: null
}

export const mutations = {
  SET_ISSUE_LIST (state, issues) {
    state.issueList = issues
  },
  UNSET_ISSUE_LIST (state) {
    state.issueList = []
  },
  SET_ISSUE_COUNT (state, count) {
    state.issueCount = count
  },
  UNSET_ISSUE_COUNT (state) {
    state.issueCount = null
  },
  SET_ISSUE_DETAIL (state, issue) {
    state.issueDetail = issue
  },
  UNSET_ISSUE_DETAIL (state) {
    state.issueDetail = null
  },
  SET_CREATE_ISSUE_DATA (state, data) {
    state.assignee_list = data.assignee_list
    state.category_list = data.category_list
    state.priority_list = data.priority_list
    state.todays_date = data.todays_date
  },
  ADD_ISSUE (state, issue) {
    state.issueList.unshift(issue)
  },
  CHANGE_ISSUE_STATUS (state, {issue_id, status}) {
    state.issueList = state.issueList.filter(issue => {
      if (issue.issue_id == issue_id) {
        issue.status = status
        if (status == 1) {
          issue.status_display = 'Open'
        } else if (status == 2) {
          issue.status_display = 'Delayed'
        } else if (status == 3) {
          issue.status_display = 'Closed'
        }
      }
      return issue
    })
  },

  ADD_ACTION_TO_ISSUE (state, action) {
    state.issueDetail.actions.unshift(action)
  },
  REMOVE_ACTION_FROM_ISSUE (state, action_id) {
    state.issueDetail.actions = state.issueDetail.actions.filter(action => {
      if (action.action_id != action_id) {
        return action
      }
    })
  }
}

export const actions = {
  getIssueList({ commit }, {perPage, page, searchQuery}) {
    ServiceIssue.getIssueList(perPage, page, searchQuery)
      .then(resp => {
        commit('SET_ISSUE_LIST', resp.data.results)
        commit('SET_ISSUE_COUNT', resp.data.count)
      })
      .catch(err => {
        console.log(err)
      })
  },

  getCreateIssueData ({commit}) {
    ServiceIssue.getCreateIssueData()
      .then(resp => {
        if (resp.status == 200) {
          commit('SET_CREATE_ISSUE_DATA', resp.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  },

  createIssue ({commit}, data) {
    return ServiceIssue.createIssue(data)
      .then(resp => {
        if (resp.status == 201) {
          commit('ADD_ISSUE', resp.data)
          const vm = new Vue()
          vm.$bvToast.toast(`Issue created Successfully.`, {
            toaster: 'b-toaster-bottom-right',
            noCloseButton: true,
            bodyClass: 'bg-info',
            autoHideDelay: 2000
          })
          return resp
        }
      })
      .catch(err => {
        console.log(err)
      })
  },

  getIssueDetail ({commit}, issue_id) {
    ServiceIssue.getIssueDetail(issue_id)
      .then(resp => {
        if (resp.status == 200) {
          commit('SET_ISSUE_DETAIL', resp.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  },

  changeIssueStatus ({commit}, {issue_id, status}) {
    ServiceIssue.changeIssueStatus({issue_id, status})
      .then(resp => {
        if (resp.status == 200) {
          commit('CHANGE_ISSUE_STATUS', {issue_id: resp.data.issue_id, status: resp.data.status})
          const vm = new Vue()
          vm.$bvToast.toast(`Issue Status Changed Successfully.`, {
            toaster: 'b-toaster-bottom-right',
            noCloseButton: true,
            bodyClass: 'bg-info',
            autoHideDelay: 2000
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  },

  createAction ({commit}, data) {
    ServiceIssue.createAction(data)
      .then(resp => {
        if (resp.status == 201) {
          commit('ADD_ACTION_TO_ISSUE', resp.data)
          const vm = new Vue()
          vm.$bvToast.toast(`Action created Successfully.`, {
            toaster: 'b-toaster-bottom-right',
            noCloseButton: true,
            bodyClass: 'bg-info',
            autoHideDelay: 2000
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  },

  removeAction ({commit}, action_id) {
    ServiceIssue.removeAction(action_id)
      .then(resp => {
        if (resp.status == 204) {
          commit('REMOVE_ACTION_FROM_ISSUE', action_id)
          const vm = new Vue()
          vm.$bvToast.toast(`Action deleted Successfully.`, {
            toaster: 'b-toaster-bottom-right',
            noCloseButton: true,
            bodyClass: 'bg-danger',
            autoHideDelay: 2000
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  },
}

export const getters = {
}