import ServiceTutorClass from '../../services/cpanel/ServiceTutorClass.js'

export const namespaced = true

export const state = {
    tutorStatDetail: null,

}

export const mutations = {

  SET_TUTOR_STAT (state, data) {
    state.tutorStatDetail = data
  },

}

export const actions = {
  getTutorStat({ commit },{}) {
    ServiceTutorClass.getClassList()
      .then(resp => {
          commit('SET_TUTOR_STAT', resp.data[0]);
      })
      .catch(err => {
        console.log(err);
      })
  },

}

export const getters = {
}