import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_class_list: 'api/v1/cpanel/tutor-class',
}

export default {
  getClassList () {
    var relativeURL = url_list.url_class_list
    return apiClient.get(relativeURL)
  },
}