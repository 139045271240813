import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_tutor: 'api/v1/ipanel/tutors/',
  url_track: '/api/v2/track/tutor-geo-cord/get-tracks/',
  url_reputation: '/api/v1/ipanel/reputation/',
  url_bank: '/api/v1/ipanel/banks'
}

export default {
  getTutorList(perPage, page, searchQuery, gender, subjects_to_teach, teaching_areas, curriculum, religion, cities) {
    var relativeURL = url_list.url_tutor + '?page_size=' + perPage + '&page=' + page

    if (searchQuery) {
      relativeURL += '&search=' + searchQuery
    }
    if (gender) {
      relativeURL += '&gender=' + gender
    }
    if (subjects_to_teach && subjects_to_teach.length>0) {
      relativeURL += '&subjects_to_teach=' + subjects_to_teach
    }
    if (teaching_areas) {
      relativeURL += '&teaching_areas=' + teaching_areas
    }
    if (curriculum) {
      relativeURL += '&curriculum=' + curriculum
    }
    if (religion) {
      relativeURL += '&religion=' + religion
    }
    if (cities) {
      relativeURL += '&cities=' + cities
    }

    return apiClient.get(relativeURL)
  },
  getAllTutorList(perPage, page) {
    var relativeURL = url_list.url_tutor + '?page_size=' + perPage + '&page=' + page
    return apiClient.get(relativeURL)
  },
  getTrackList(tutor_id, perPage, page) {
    var trackURL = url_list.url_track + "?tutor_id=" + tutor_id
    trackURL =  trackURL + '&page_size=' + perPage + '&page=' + page
    return apiClient.get(trackURL)
  },
  getReputationList(tutor_id, perPage, page) {
    var reputationURL = url_list.url_reputation + "?tutor_id=" + tutor_id
    reputationURL =  reputationURL + '&page_size=' + perPage + '&page=' + page
    return apiClient.get(reputationURL)
  },
  getConstanceSchedule() {
    return apiClient.get(url_list.url_tutor + 'get-tutor-schedule/')
  },
  getConstanceScheduleWithoutPermission() {
    return apiClient.get("/api/v1/ipanel/tutor-schedule")
  },
  getCpanelSchedule() {
    return apiClient.get("api/v1/cpanel/tutor/get-tutor-schedule")
  },
  createTutor(data) {
    return apiClient.post(url_list.url_tutor, data)
  },
  getTutorDetail(tutor_id) {
    return apiClient.get(url_list.url_tutor + tutor_id + '/')
  },
  updateTutor(form_data) {
    return apiClient.patch(url_list.url_tutor + form_data.tutor_id + '/', form_data.data)
  },
  getBankNameList() {
    return apiClient.get(url_list.url_bank)
  },
  createTutorNotes(data) {
    return apiClient.post(url_list.url_tutor + data.tutor + "/tutor-notes/", data)
  },
  deleteTutorNotes(tutor_id, id) {
    return apiClient.delete(url_list.url_tutor + tutor_id + "/tutor-notes/" + id)
  },
  getTutorStatistic(tutor_id) {
    return apiClient.get(url_list.url_tutor + tutor_id + "/tutor-stats/")
  },
  getTutorListChoices() {
    return apiClient.get(url_list.url_tutor + "get-tutor-list-choices")
  },
  getAllTutors() {
    return apiClient.get(url_list.url_tutor + "get-all-tutors/")
  }
}