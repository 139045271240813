import ServiceClass from '../../services/ipanel/ServiceClass.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const namespaced = true

export const state = {
    classList: [],
    classCount: null,

    totalPendingClasses:null,
    totalCompletedClasses:null,

    classDetail: null,
    editor: ClassicEditor,
    classPackageList:[],
    editorConfig: {
        toolbar: {
        items: [
            "bold",
            "italic",
            "BulletedList",
            "NumberedList",
            "undo",
            "redo"
        ]
        }
    },

}

export const mutations = {
    SET_CLASS_LIST(state, classes) {
        state.classList = classes
    },
    UNSET_CLASS_LIST(state) {
        state.classList = []
    },
    SET_CLASS_COUNT(state, count) {
        state.classCount = count

        state.totalPendingClasses = 0
        state.totalCompletedClasses = 0
        state.classList.filter(class_ =>{
            if(class_.completion_status == 1 ){
                state.totalPendingClasses += 1
            }
            if(class_.completion_status == 2 ){
                state.totalCompletedClasses += 1
            }
        })
    },
    UNSET_CLASS_COUNT(state) {
        state.classCount = null
    },
    SET_CLASS_DETAIL(state, classes) {
        state.classDetail = classes
    },
    UNSET_CLASS_DETAIL(state) {
        state.classDetail = null
    },
    ADD_CLASS(state, classes) {
        state.classList.unshift(classes)
    },
    ADD_BULK_CLASS(state, classes){
        classes.filter( class_ => {
            state.classList.unshift(class_)
            state.classCount += 1
        });
    },
    SET_CLASS_PACKAGE_LIST(state, data){
        state.classPackageList = data
    },
    UPDATE_CLASS_LIST(state, data){
        const classes_id = data.id
        state.classList = state.classList.filter(classes => {
            if (classes.id == classes_id) {
            Object.assign(classes, data);
            }
            return classes
        })
    }
}

export const actions = {
    getClassList({
        commit
    }, {
        perPage,
        page,
        searchQuery,
        tutor,
        student, 
        filter_value,
        custom_filter_value
    }) {
        return ServiceClass.getClassList(perPage, page, searchQuery,tutor,student, filter_value,custom_filter_value)
            .then(resp => {
                commit('SET_CLASS_LIST', resp.data.results)
                commit('SET_CLASS_COUNT', resp.data.count)
            })
            .catch(err => {
                console.log(err)
            })
    },
    createClass({
        commit
    }, data) {
        return ServiceClass.createClass(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_CLASS', resp.data)
                    Vue.toasted.success('Class Created Successfully.');
                }
                return resp
            })
            .catch(err => {
                console.log(err)
            })
    },
    createBulkClass({
        commit
    }, data) {
        return ServiceClass.createBulkClass(data)
            .then(resp => {
                if (resp.status == 200) {
                    Vue.toasted.error(resp.data.message);
                }
                if (resp.status == 201) {
                    commit('ADD_BULK_CLASS', resp.data)
                    Vue.toasted.success('Class Created Successfully.');
                }
                
            })
            .catch(err => {
                console.log(err)
            })
    },
    getClassPackageList({ commit }){
        ServiceClass.getClassPackageList()
        .then(resp => {
          commit('SET_CLASS_PACKAGE_LIST', resp.data)
        })
    },

    getClassDetail({
        commit
    }, id) {
        return ServiceClass.getClassDetail(id)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_CLASS_DETAIL', resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    classUpdate({
        commit
    }, {id, data}) {
        return ServiceClass.classUpdate(id, data)
            .then(resp => {
                if (resp.status == 200) {
                    commit('UPDATE_CLASS_LIST', resp.data)
                    Vue.toasted.success('Class Updated Successfully.');
                }
                return resp
            })
            .catch(err => {
                console.log(err)
            })
    },
}

export const getters = {}