import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_invoice: 'api/v1/ipanel/invoices/',
}


export default {
    getInvoiceList (perPage, page, searchQuery) {
        var relativeURL = url_list.url_invoice + '?page_size=' + perPage + '&page=' + page
        if (searchQuery) {
          relativeURL += '&search=' + searchQuery
        }
        return apiClient.get(relativeURL)
      },

    getInvoiceDetail(id){
      return apiClient.get(url_list.url_invoice  + id + '/')
    },

    updateInvoice(data){
      return apiClient.patch(url_list.url_invoice  + data.id +'/', data.data)
    },

    getPaymentStatus() {
      return apiClient.get(url_list.url_invoice + 'get-payment-status/')
    },

    getInstallmentList(id){
      return apiClient.get(url_list.url_invoice  + id + '/installments/')
    },

    createInstallment(data){
      return apiClient.post(url_list.url_invoice + data.id + '/installments/', data.data)
    },

    getInstallmentDetail(data){
      return apiClient.get(url_list.url_invoice  + data.invoice_id + '/installments/' + data.installment_id + '/')
    },

    updateInstallment(data){
      return apiClient.patch(url_list.url_invoice  + data.invoice_id + '/installments/' + data.installment_id + '/' ,data.data)
    },
    
    removeInstallment(data){
      return apiClient.delete(url_list.url_invoice  + data.invoice_id + '/installments/' + data.installment_id + '/')
    },

    createInvoice(data){
      return apiClient.post(url_list.url_invoice, data)
    },

    deleteInvoice(id){
      return apiClient.delete(url_list.url_invoice + id)
    },
}