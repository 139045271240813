import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_student: 'api/v1/ipanel/students/',
}

export default {
  getStudentList(perPage, page, searchQuery, gender, subjects_to_study, address, status, religion) {
    var relativeURL = url_list.url_student + '?page_size=' + perPage + '&page=' + page

    if (searchQuery) {
      relativeURL += '&search=' + searchQuery
    }
    if (gender) {
      relativeURL += '&gender=' + gender
    }
    if (subjects_to_study.length>0) {
      relativeURL += '&subjects_to_study=' + subjects_to_study
    }
    if (address) {
      relativeURL += '&address=' + address
    }
    if (status) {
      relativeURL += '&status=' + status
    }
    if (religion) {
      relativeURL += '&religion=' + religion
    }
    return apiClient.get(relativeURL)
  },
  getAllStudentList(perPage, page) {
    var relativeURL = url_list.url_student + '?page_size=' + perPage + '&page=' + page
    return apiClient.get(relativeURL)
  },
  creatStudent(data) {
    return apiClient.post(url_list.url_student, data)
  },
  getStudentDetail(student_id) {
    return apiClient.get(url_list.url_student + student_id + '/')
  },
  getStudentUpdate(form_data) {
    return apiClient.patch(url_list.url_student + form_data.student_id + '/', form_data.data)
  },
  getSourceChannel() {
    return apiClient.get(url_list.url_student + "get-source-channel-list/")
  },
  getStudentReligionList() {
    return apiClient.get(url_list.url_student + "get-student-religion-list/")
  },
  createStudentNotes(data) {
    return apiClient.post(url_list.url_student + data.student + "/student-notes/", data)
  },
  deleteStudentNotes(student_id, id) {
    return apiClient.delete(url_list.url_student + student_id + "/student-notes/" + id)
  },
  getStudentStatistic(student_id) {
    return apiClient.get(url_list.url_student + student_id + "/student-stats/")
  },
  changeDefaultPassword(form_data) {
    return apiClient.patch(url_list.url_student + form_data.student_id + '/', form_data.data)
  },
  getStudentListChoices(){
    return apiClient.get(url_list.url_student + "get-student-list-choices/")
  }
}