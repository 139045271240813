<template>
    <div>
        <div class="container my-4" style="font-size:14px;">
            <b-row>
                <b-col cols="12" md="9" class="mb-4">
                    <div class="bg-white shadow-sm" v-if="isProcessing">
                        <ContentLoader width="500" height="400">
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="400" />
                        </ContentLoader>
                    </div>
                    <div class="bg-white shadow-sm p-4" v-if="!isProcessing && postNotFound">
                        <h1 class="blog-title">Article Not Found</h1>
                    </div>
                    <div class="bg-white shadow-sm p-4" v-if="!isProcessing && post">
                        <b-row>
                            <b-col cols="12">
                                <h1 class="blog-title"><b>{{post.title}}</b></h1>
                            </b-col>
                            <b-col cols="12" class="mb-2">
                                <span class="mr-2"><font-awesome-icon icon="calendar-alt" /> <small>{{post.date}}</small></span>
                                <span class="mr-2">
                                    <small v-show="post.views">
                                        <font-awesome-icon icon="eye" />
                                        {{post.views}}
                                    </small>
                                </span>
                                <span>
                                    <small v-show="post.category">
                                        <font-awesome-icon icon="tag" />
                                        <span v-for="category, index of post.category" :key="category.id">
                                            <a class="text-ip" href="#" @click.prevent="gotoCategory(category.title)">{{ category.title }}</a>
                                            <span v-show="index + 1 < post.category.length">, </span>
                                        </span>
                                    </small>
                                </span>
                            </b-col>
                            <b-col v-if="convertUrl" cols="12">
                                <b-embed
                                    type="iframe"
                                    aspect="16by9"
                                    :src="convertUrl"
                                    allowfullscreen
                                ></b-embed>
                            </b-col>
                            <b-col cols="12" class="mt-2">
                                <span v-html="post.description"></span>
                            </b-col>
                            <!-- TAGS -->
                            <b-col cols="12" class="mt-4" v-if="post.category && post.category.length>0">
                                <i class="fa fa-eye" aria-hidden="true"></i>
                                <span v-for="category,index in post.category" :key="index">
                                    <b-link @click="gotoCategory(category.title)" class="text-ip">
                                        <small><b>{{category.title}}</b></small>
                                        <span v-if="index!=post.category.length-1">, </span>
                                    </b-link>
                                </span>
                            </b-col>
                        </b-row>

                        <b-row v-if="blogAdHtml" class="mt-4">
                            <div class="m-2 p-4 bg-light rounded w-100" v-html="blogAdHtml"></div>
                        </b-row>
                    </div>
                </b-col>
                <b-col cols="12" md="3">
                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="">
                                <div class="input-group input-full-width">
                                    <b-input
                                    v-model="searchQuery"
                                    type="text"
                                    placeholder="Search Here"
                                    ></b-input>
                                    <div class="input-group-append">
                                        <b-button :to="{ path: '/blog/?search=' + searchQuery }"
                                        type="submit" 
                                        variant="primary" 
                                        class="w-30">
                                            <b>Search</b>
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                        </b-col>
                        <b-col class="bg-white shadow-sm p-4 mx-3 mt-2">
                            <span class="mt-2"><h6><b>Categories</b></h6></span>
                            <span>
                                <b-link :to="{name: 'version2-blog-list'}" class="text-ip">
                                    All, 
                                </b-link>
                            </span>
                            <span v-for="category,index in category_list" :key="index">
                                <b-link @click="gotoCategory(category.text)" class="text-ip">
                                    {{category.text}}
                                </b-link>
                                <span v-if="category_list.length-1!=index">, </span>
                            </span>
                        </b-col>
                        <b-col v-if="latestPostList.length>0" class="mt-4" cols="12">
                            <span ><h6><b>Latest 6 Articles</b></h6></span>
                        </b-col>
                        <b-col class="bg-white shadow-sm p-4 mx-3">
                            <span v-for="post,index in latestPostList" :key="index">
                                <b-link @click="goto(post)" class="text-ip">
                                    {{post.title}}
                                </b-link>
                                <span v-if="latestPostList.length-1!=index"><hr> </span>
                            </span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
import { ContentLoader } from "vue-content-loader"
import {BEmbed} from 'bootstrap-vue'

export default {
  props: ['day','month','year','slug'],
  components: {
      ContentLoader,
      BEmbed
  },
  computed: {
    ...mapState(["blog"]),
    category_list(){
        if (this.blog.categoryList) 
            return this.blog.categoryList
        else []
    },
    convertUrl(){
        if (this.post.ytb_video_id)
            return `https://www.youtube.com/embed/${this.post.ytb_video_id}`
        else return null
    },
  },

  created() {
    this.fetchPost()
    this.fetchCategoryList()
    this.fetchLatestPostList()
    this.getCompanyCode()
    this.getBlogAd()
  },

  data() {
    return {
        companyCode: null,
        blogAdHtml: null,
        post: null,
        postList: [],
        postCount: null,
        latestPostList: [],
        searchQuery: null,
        isSearching: false,
        isProcessing: false,
        postNotFound: false,
    };
  },

  methods: {
    gotoCategory(title){
        this.$router.push({ 
            name: 'version2-blog-category', 
            params: { category_name: title}
        })
    },
    getCompanyCode() {
        axios.get("/api/get-company-code/")
        .then(resp=>{
            if (resp.status==200) {
                this.companyCode = resp.data.company_code
            }
        })
    },
    getBlogAd() {
        axios.get("/api/get-blog-ad/")
        .then(resp=>{
            if (resp.status==200) {
                this.blogAdHtml = resp.data.ad_html
            }
        })
    },
    trimTitle(complete_title){
        var title = ''
        var total_length = 0
        var list_of_words = complete_title.split(" ")
        for (var i=0; i < list_of_words.length; i++){
            total_length = total_length + list_of_words[i].length + 1
            if (total_length < 45){
                var space = i!=0?' ':''
                title = title + space + list_of_words[i]
            }
            else{
                break
            }
        }
        document.title = "Instaprivat" + "-" + title
    },
    fetchPost() {
        this.isProcessing = true
        axios.get(`/api/v1/blog/posts/${this.slug}/`)
        .then(resp=>{
            if (resp.status == 200) {
                this.isProcessing = false
                this.post = resp.data
                if (resp.data.title.length > 45){
                    this.trimTitle(resp.data.title)
                }else{
                    document.title = "Instaprivat" + "-" + resp.data.title
                }
            }
        })
        .catch(error=>{
            this.isProcessing = false
            if (error.response.status == 404) {
                this.postNotFound = true
            }
        })
    },
    fetchCategoryList() {
        this.$store.dispatch('blog/getAllCategories')
    },
    fetchLatestPostList() {
        if (this.isSearching==false){
            axios.get(`/api/v1/blog/posts/?page_size=6&page=${this.$route.query.page || 1}`)
            .then(resp=>{
                if (resp.status==200) {
                    this.latestPostList = resp.data.results
                }
            })
        }
        else this.searchPost()
    },
    goto(post){
        const parts = post.date.split("-");
        var day = parseInt(parts[parts.length-1]) 
        var month = parseInt(parts[parts.length-2])
        var year = parseInt(parts[parts.length-3])
        this.$router.push({ 
            name: 'version2-blog-detail', 
            params: { day: day, month: month, year: year, slug: post.slug} 
        })
        .catch(error=>{})
    },
    searchPost(){
        if (this.searchQuery) {
            axios.get(`/api/v1/blog/posts/?search=${this.searchQuery}&page_size=${this.perPage}&page=${this.$route.query.page || 1}`)
            .then(resp=>{
                if (resp.status==200) {
                    this.postList = []
                    this.postList = resp.data.results
                    this.postCount = resp.data.count
                    this.isSearching = true
                }
            })
        }
    },
  },
  watch: {
    '$route.params.slug': function(){
        this.$nextTick(()=>{
            this.fetchPost()
        })
    }
  }
};
</script>

<style scoped>
.text-ip {
  color: #155974 !important;
}
.blog-title {
    font-size: 24px;
}
</style>