import Vue from 'vue';
import VueClipboard from 'vue-clipboard2'
import * as filters from './filters.js'

const Sidebar = () => import ('../../frontend/src/components/instapanel/sidebar/Sidebar.vue')
const MobileNavbar = () => import ('../../frontend/src/components/instapanel/sidebar/MobileNavbar.vue')
const Navbar = () => import ('./components/home/Navbar.vue')
const SpanelNavbar = () => import ('./components/spanel/Navbar.vue')
const HomeFooter = () => import ('./components/home/HomeFooter.vue')

import store from './stores'

import CKEditor from '@ckeditor/ckeditor5-vue';
import Select2 from 'v-select2-component';
import router from './router'

// new Imported
import Toasted from "vue-toasted";

Vue.use(VueClipboard)

Vue.component('Select2', Select2);
Vue.use(CKEditor)


import {
  library
} from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import * as fsvi from '@fortawesome/free-solid-svg-icons'
import * as fbsi from '@fortawesome/free-brands-svg-icons'

library.add(
  fbsi.faFontAwesome, fsvi.faUserSecret, fsvi.faTachometerAlt, fsvi.faRssSquare, fsvi.faFileInvoiceDollar,
  fsvi.faLink, fsvi.faPowerOff, fsvi.faPlusSquare, fsvi.faUserAlt, fsvi.faTrashAlt, fsvi.faFilePdf,
  fsvi.faCheckCircle, fsvi.faHourglassHalf, fsvi.faTimes, fsvi.faTimesCircle, fsvi.faHistory,
  fsvi.faBookOpen, fsvi.faEye, fsvi.faClock, fsvi.faUser, fsvi.faPencilAlt, fsvi.faCalendar, fsvi.faCalendarAlt,
  fsvi.faTag, fsvi.faSearch, fsvi.faTrash, fsvi.faRedo, fsvi.faArrowCircleRight, fbsi.faInstagramSquare, fbsi.faTiktok
)
Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.use(Toasted, {
  class: "rounded",
  position: "bottom-left",
  fitToScreen: true,
  theme: "toasted-primary",
  duration: 4000,
});

window.Vue = Vue;


/*** BOOSTRAP IMPORT */
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { CollapsePlugin } from 'bootstrap-vue'

import {
  BCard,
  BAlert,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BLink,
  BImg,
  BRow,
  BCol,
  VBModal,
  BModal,
  BPagination,
  BTable,
  BFormFile,
  BFormTextarea,
  BBadge,
  BFormCheckbox,
  BSpinner,
  BFormText,
  BPaginationNav,
  BContainer,
  BFormDatepicker,
  BFormTimepicker,
  ModalPlugin,
  BTabs,
  BTab,
  BProgressBar,
  BProgress
} from "bootstrap-vue";

Vue.use(ModalPlugin)
Vue.use(CollapsePlugin)

//bootstrap-vue components
Vue.component("BCard", BCard);
Vue.component("BAlert", BAlert);
Vue.component("BButton", BButton);
Vue.component("BProgress", BProgress);
Vue.component("BProgressBar", BProgressBar);
Vue.component("BForm", BForm);
Vue.component("BInput", BFormInput);
Vue.component("BFormGroup", BFormGroup);
Vue.component("BLink", BLink);
Vue.component("BImg", BImg);
Vue.component("BRow", BRow);
Vue.component("BCol", BCol);
Vue.component("BModal", BModal);
Vue.component("BPagination", BPagination);
Vue.component("BTable", BTable);
Vue.component("BFormFile", BFormFile);
Vue.component("BFormTextarea", BFormTextarea);
Vue.component("BBadge", BBadge);
Vue.component("BFormCheckbox", BFormCheckbox);
Vue.component("BSpinner", BSpinner);
Vue.component("BFormText", BFormText);
Vue.component("BPaginationNav", BPaginationNav);
Vue.component("BContainer", BContainer);
Vue.component("BFormDatepicker", BFormDatepicker);
Vue.component("BFormTimepicker", BFormTimepicker);
Vue.component("BTabs", BTabs);
Vue.component("BTab", BTab);

// bootstrap-vue directives
Vue.directive("b-modal", VBModal);

const app = new Vue({
  el: '#app',
  store,
  router,
  delimiters: ['${', '}'],
  components: {
    Sidebar,
    Navbar,
    HomeFooter,
    MobileNavbar,
    SpanelNavbar
  },
  created(){
    this.$store.dispatch('user/getUserInfo')
  }
});