import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  change_password: 'api/v1/cpanel/auth/password_change',
  change_default_password: 'api/v1/cpanel/auth/change_default_password',
  change_student_default_password: 'api/v1/cpanel/auth/change_student_default_password',
}

export default {
  changePassword (data) {
    return apiClient.post(url_list.change_password, data)
  },
  changeDefaultPassword (data) {
    return apiClient.post(url_list.change_default_password, data)
  },
  changeStudentDefaultPassword (data) {
    return apiClient.post(url_list.change_student_default_password, data)
  },
}