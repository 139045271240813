import ServiceStudent from '../../services/ipanel/ServiceStudent.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ServiceAuth from '../../services/ServiceAuth.js';

export const namespaced = true

export const state = {
    studentList: [],
    religionList: [],
    studentCount: null,
    totalInactiveStudent: 0,
    totalAssignedStudent: 0,
    totalUnAssignedStudent: 0,
    studentStatistic: null,
    studentDetail: null,
    sourceCannelList: [],

    editor: ClassicEditor,
    editorConfig: {
        toolbar: {
            items: [
                "bold",
                "italic",
                "BulletedList",
                "NumberedList",
                "undo",
                "redo"
            ]
        }
    }

}

export const mutations = {
    SET_STUDENT_LIST(state, students) {
        state.studentList = students
    },
    UNSET_STUDENT_LIST(state) {
        state.studentList = []
    },
    SET_STUDENT_COUNT(state, data) {
        state.studentCount = data.count
    },
    UNSET_STUDENT_COUNT(state) {
        state.studentCount = null
    },
    SET_STUDENT_DETAIL(state, student) {
        state.studentDetail = student
    },
    UNSET_STUDENT_DETAIL(state) {
        state.studentDetail = null
    },
    ADD_STUDENT(state, student) {
        state.studentList.unshift(student)
    },
    SET_UPDATED_STUDENT(state, data) {
        const student_id = data.id
        state.studentList = state.studentList.filter(student => {
            if (student.id == student_id) {
                Object.assign(student, data);
            }
            return student
        });
    },
    UPDATE_STUDENT_DETAIL(state, data) {
        state.studentDetail = data
    },
    SET_SOURCE_CHANNEL_LIST(state, data) {
        state.sourceCannelList = data
    },
    SET_RELIGION_LIST(state, data) {
        state.religionList = data
    },
    ADD_NOTE(state, note) {
        state.studentDetail.notes.unshift(note)
    },
    REMOVE_NOTE(state, note_id) {
        state.studentDetail.notes = state.studentDetail.notes.filter(note => {
            if (note.id != note_id) {
                return note
            }
        });
    },
    SET_STUDENT_STAISTIC(state, data) {
        state.studentStatistic = data
    }
}

export const actions = {
    getStudentList({
        commit
    }, {
        perPage,
        page,
        searchQuery,
        gender,
        subjects_to_study,
        address,
        status,
        religion
    }) {
        ServiceStudent.getStudentList(perPage, page, searchQuery, gender, subjects_to_study, address, status, religion)
            .then(resp => {
                commit('SET_STUDENT_LIST', resp.data.results)
                commit('SET_STUDENT_COUNT', {count: resp.data.count})
            })
            .catch(err => {
                console.log(err)
            })
    },

    creatStudent({
        commit
    }, data) {
        return ServiceStudent.creatStudent(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_STUDENT', resp.data)
                    Vue.toasted.success('Student created Successfully');
                    return resp
                }
            })
            .catch(err => {
                console.log(err)
            })
    },

    getStudentDetail({
        commit
    }, student_id) {
        return ServiceStudent.getStudentDetail(student_id)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_STUDENT_DETAIL', resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    getStudentUpdate({
        commit
    }, {
        student_id,
        data
    }) {
        return ServiceStudent.getStudentUpdate({
                student_id,
                data
            })
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_UPDATED_STUDENT', resp.data)
                    commit('UPDATE_STUDENT_DETAIL', resp.data)
                }
                Vue.toasted.success('Student Updated Successfully.');
            })
            .catch(err => {
                console.log(err)
            })
    },
    getSourceChannel({
        commit
    }) {
        ServiceStudent.getSourceChannel()
            .then(resp => {
                commit('SET_SOURCE_CHANNEL_LIST', resp.data)
            })
    },
    getStudentReligionList({
        commit
    }) {
        ServiceStudent.getStudentReligionList()
            .then(resp => {
                commit('SET_RELIGION_LIST', resp.data)
            })
    },

    createStudentNotes({
        commit
    }, data) {
        ServiceStudent.createStudentNotes(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_NOTE', resp.data)
                    Vue.toasted.success('Notes Created Successfully.');
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    deleteStudentNotes({
        commit
    }, {
        student_id,
        id
    }) {
        return ServiceStudent.deleteStudentNotes(student_id, id)
            .then(resp => {
                if (resp.status == 204) {
                    commit('REMOVE_NOTE', id)
                    Vue.toasted.error('Notes removed Successfully.');
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    getStudentStatistic({
        commit
    }, student_id) {
        ServiceStudent.getStudentStatistic(student_id)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_STUDENT_STAISTIC', resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    changeDefaultPassword({commit}, data) {
        return ServiceAuth.changeStudentDefaultPassword(data)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_UPDATED_STUDENT', resp.data)
                    commit('UPDATE_STUDENT_DETAIL', resp.data)
                }
                Vue.toasted.success('Password Changed');
                return resp
            })
            .catch(err => {
                console.log(err)
            })
    },
}

export const getters = {}