import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_grade: 'api/v1/ipanel/grades/',
}

export default {
    getGradeList(perPage, page, searchQuery) {
        var relativeURL = url_list.url_grade + '?page_size=' + perPage + '&page=' + page

        if (searchQuery) {
          relativeURL += '&search=' + searchQuery
        }
        return apiClient.get(relativeURL)
      },
    createGrade(data) {
      return apiClient.post(url_list.url_grade, data)
    },
    getGradeDetail(grade_id) {
      return apiClient.get(url_list.url_grade + grade_id + '/')
    },
    updateGrade(form_data) {
      return apiClient.patch(url_list.url_grade + form_data.grade_id + '/', form_data.data)
    },
    
}