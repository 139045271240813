import ServiceInvoice from '../../services/ipanel/ServiceInvoice.js'
import ServiceDiscount from '../../services/ipanel/ServiceDiscount.js'
export const namespaced = true

export const state = {
  invoiceList: [],
  installmentsList : [],
  invoiceCount: null,
  totalPendingInvoice:null,
  totalCompletedInvoice:null,
  totalCanceledInvoice:null,
  invoiceDetail: null,
  discountList:[],
  additionalCostList:[],
  paymentStatusList:[],
  installmentDetail:null
}


export const mutations = {
  
    SET_INVOICE_LIST(state,data){
        state.invoiceList = data
    },
    SET_INVOICE_COUNT(state, count){
        state.invoiceCount = count
        state.totalPendingInvoice = 0
        state.totalCompletedInvoice = 0
        state.totalCanceledInvoice = 0
        state.invoiceList.filter(invoice =>{
            if(invoice.payment_status == 1 ){
                state.totalPendingInvoice += 1
            }
            if(invoice.payment_status == 2 ){
                state.totalCompletedInvoice += 1
            }
            if(invoice.payment_status == 3 ){
              state.totalCanceledInvoice += 1
          }
        })
    },
    // UNSET_INVOICE_COUNT(state,data){
    //   state.invoiceCount = null
    // },
    SET_INVOICE_DETAIL(state, data){
      state.invoiceDetail = data
    },
    UNSET_INVOICE_DETAIL(state){
      state.invoiceDetail = null
    },
    SET_PAYMENT_STATUS_LIST(state,data){
      state.paymentStatusList = data
    },
    UNSET_PAYMENT_STATUS_LIST(state){
      state.paymentStatusList = []
    },
    SET_DISCOUNT_LIST(state, data){
      state.discountList = []
      state.discountList = data.results
    },
    UNSET_DISCOUNT_LIST(state){
      state.discountList = []
    },
    SET_ADDITIONAL_COST_LIST(state, data){
      state.additionalCostList = []
      state.additionalCostList = data.additional_cost_list
    },
    UNSET_ADDITIONAL_COST_LIST(state){
      state.additionalCostList = []
    },
    UPDATE_INVOICE_LIST(state, data){
      const invoice_id = data.id
      state.invoiceList = state.invoiceList.filter(invoice => {
          if(invoice.id == invoice_id){
             Object.assign(invoice, data);
          }
          return invoice
      });
    },
    SET_INSTALLMENT_LIST(state,data){
      state.installmentsList = data
    },
    UNSET_INSTALLMENT_LIST(state){
      state.installmentsList = []
    },
    INSERT_ITEM_TO_INSTALLMENT_LIST(state,data){
      state.installmentsList.unshift(data)
    },
    SET_INSTALLMENT_DETAIL(state,data){
      state.installmentDetail = data
    },
    UNSET_INSTALLMENT_DETAIL(state){
      state.installmentDetail = null
    },
    UPDATE_INSTALLMENT_LIST(state,data){
      const installment_id = data.id
      state.installmentsList = state.installmentsList.filter(installment => {
        if (installment.id == installment_id) {
          Object.assign(installment, data);
        }
          return installment
      })
    },
    REMOVE_INSTALLMENT (state, installment_id) {
      state.installmentsList = state.installmentsList.filter(installment => {
        // Adding Classes Of deleted Installment To eligible classes
        if (installment.id == installment_id){
          for (const class_ in installment.classes) {
            state.invoiceDetail.eligible_classes.unshift(installment.classes[class_])
          }
        }
        // Removing Deleted Classes from installment List
        if (installment.id != installment_id) {
          return installment
        }
      })
    },
    UPDATE_ELIGIBLE_CLASSES(state, data){
      //Removing the class from eligible classes
      for (const class_ in data.classes) {
        state.invoiceDetail.eligible_classes = state.invoiceDetail.eligible_classes.filter(eligible_class => {
          if(eligible_class.id != data.classes[class_].id){
            return eligible_class
          }
        })
    }
  }

}

export const actions = {
      getInvoiceList({ commit }, {perPage, page, searchQuery}) {
        return ServiceInvoice.getInvoiceList(perPage, page, searchQuery)
          .then(resp => {
            commit('SET_INVOICE_LIST', resp.data.results)
            commit('SET_INVOICE_COUNT', resp.data.count)
          })
          .catch(err => {
            console.log(err)
          })
      },

      createInvoice({}, data){
        console.log(data);
        return ServiceInvoice.createInvoice(data)
        .then(resp => {
          if(resp.status == 200){
            Vue.toasted.error('No classes to be Invoiced.');
            return resp
          }
          if(resp.status == 201){
            Vue.toasted.success('Invoice Created Successfully.');
            return resp
          }
          
        })
      },

      getInvoiceDetail({ commit,getters }, id){
        ServiceInvoice.getInvoiceDetail(id)
        .then(resp => {
          commit('SET_INVOICE_DETAIL',resp.data)
        })
      },
      updateInvoice({ commit },{ id , data }){
        return ServiceInvoice.updateInvoice({
          id:id,
          data:data
        })
        .then(resp => {
          if(resp.status == 200){
            Vue.toasted.success('Invoice Updated Successfully.');
            commit('UPDATE_INVOICE_LIST',resp.data)
            return resp
          }
        })
      },
      deleteInvoice({commit},{id}){
        return ServiceInvoice.deleteInvoice(id)
        .then(resp => {
          if (resp.status==204){
            Vue.toasted.error('Invoice Deleted Successfully.');
          }
          return resp
        })
      },
      getDiscountList({ commit }){
        ServiceDiscount.getDiscountList(100,1,"")
        .then(resp => {
          commit('SET_DISCOUNT_LIST', resp.data)
        })
      },
      getAdditionalCostList({ commit }){
        ServiceDiscount.getAdditionalCostList()
        .then(resp => {
          commit('SET_ADDITIONAL_COST_LIST', resp.data)
        })
      },
      getPaymentStatus({  commit }){
        ServiceInvoice.getPaymentStatus()
        .then(resp => {
          commit('SET_PAYMENT_STATUS_LIST',resp.data.payment_status_list)
        })
      },
      getInstallmentList({ commit}, id){
        ServiceInvoice.getInstallmentList(id)
        .then(resp => {
          commit('SET_INSTALLMENT_LIST',resp.data)
        })
      },
      createInstallment({ commit }, {id, data}){
        ServiceInvoice.createInstallment({
          id : id ,
          data : data 
        })
        .then(resp => {
          if(resp.status == 201){
            Vue.toasted.success('Installment Created Successfully.');
            commit('INSERT_ITEM_TO_INSTALLMENT_LIST',resp.data)
            commit('UPDATE_ELIGIBLE_CLASSES',resp.data)
          }
          
        })
      },
      getInstallmentDetail({ commit,getters },{ invoice_id, installment_id }){
        var installment  = getters.getInstallmentFromGetters(installment_id)
        if(installment){
          commit('SET_INSTALLMENT_DETAIL',installment)
        }else{
          ServiceInvoice.getInstallmentDetail({
            invoice_id: invoice_id,
            installment_id: installment_id
          })
          .then(resp => {
            if(resp.status = 200){
              commit('SET_INSTALLMENT_DETAIL',resp.data)
            }
          })
        }
      },
      updateInstallment({ commit },{invoice_id, installment_id, data}){
        ServiceInvoice.updateInstallment({
          invoice_id:invoice_id,
          installment_id:installment_id,
          data:data
        })
        .then(resp => {
          if(resp.status == 200){
            Vue.toasted.success('Installment Updated Successfully.');
            commit('UPDATE_INSTALLMENT_LIST',resp.data)
          }
          
        })
      },
      removeInstallment({ commit },{ invoice_id, installment_id }){
        ServiceInvoice.removeInstallment({
          invoice_id:invoice_id,
          installment_id:installment_id
        })
        .then(resp => {
          if(resp.status == 204){
            Vue.toasted.success('Installment Deleted Successfully.');
            commit('REMOVE_INSTALLMENT',installment_id)
          }
        })
      }

}

export const getters = {
    // getInvoiceFromGetters: state => id => {
    //   return state.invoiceList.find(invoice => invoice.id === id)
    // },
    getInstallmentFromGetters: state => id => {
      return state.installmentsList.find(installment => installment.id === id)
    }
  }