import ServiceSubject from '../../services/ipanel/ServiceSubject.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const namespaced = true

export const state = {
    subjectList: [],
    subjectCount: null,
    subjectDetail:null,

    editor: ClassicEditor,
    editorConfig:{
      toolbar: {
          items: [
              "bold",
              "italic",
              "BulletedList",
              "NumberedList",
              "undo",
              "redo"
          ]
      }
    }
    
}

export const mutations = {
    SET_SUBJECT_LIST(state, subjects) {
        state.subjectList = subjects
    },
    UNSET_SUBJECT_LIST(state) {
        state.subjectList = []
    },
    SET_SUBJECT_COUNT(state, count) {
        state.subjectCount = count
    },
    UNSET_SUBJECT_COUNT(state) {
        state.subjectCount = null
    },
    SET_SUBJECT_DETAIL(state, subject) {
        state.subjectDetail = subject
    },
    UNSET_SUBJECT_DETAIL(state) {
        state.subjectDetail = null
    },
    ADD_SUBJECT(state, subject) {
        state.subjectList.unshift(subject)
    },
    SET_UPDATED_SUBJECT(state, data){
        const subject_id = data.id
        state.subjectList = state.subjectList.filter(subject => {
            if(subject.id == subject_id){
                Object.assign(subject, data);
            }
            return subject
        });
    },
    UPDATE_DISCOUNT_DETAIL(state, data){
        state.subjectDetail = data
    },
}

export const actions = {
    getSubjectList({
        commit
    }, {
        perPage,
        page,
        searchQuery
    }) {
        ServiceSubject.getSubjectList(perPage, page, searchQuery)
            .then(resp => {
                commit('SET_SUBJECT_LIST', resp.data.results)
                commit('SET_SUBJECT_COUNT', resp.data.count)
            })
            .catch(err => {
                console.log(err)
            })
    },

    createSubject({
        commit
    }, data) {
        ServiceSubject.createSubject(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_SUBJECT', resp.data)
                    Vue.toasted.success('Subject created Successfully');
                }
            })
            .catch(err => {
                console.log(err)
            })
    },

    getSubjectDetail({ commit }, subject_id) {
        return ServiceSubject.getSubjectDetail(subject_id)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_SUBJECT_DETAIL', resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    updateSubject({  commit }, { subject_id, data }) {
        return ServiceSubject.updateSubject({
            subject_id,
            data
        })
        .then(resp => {
            if (resp.status == 200) {
                commit('SET_UPDATED_SUBJECT', resp.data)
            }
            Vue.toasted.success('subject Updated Successfully.');
        })
        .catch(err => {
            console.log(err)
        })
    },
}

export const getters = {}