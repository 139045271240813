import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_partner: 'api/v1/ipanel/marketing-partners/',
}


export default {
    getPartnerList(perPage, page, searchQuery) {
        var relativeURL = url_list.url_partner + '?page_size=' + perPage + '&page=' + page
        if (searchQuery) {
            relativeURL += '&search=' + searchQuery
        }
        return apiClient.get(relativeURL)
    },
    createPartner(data) {
        return apiClient.post(url_list.url_partner, data)
    },
    updatePartner(form_data) {
        return apiClient.patch(url_list.url_partner + form_data.id + '/', form_data.data)
    },
}