import ServiceJobpost from '../../services/ipanel/ServiceJobpost.js'
export const namespaced = true

export const state = {
  jobpostList: [],
  jobpostCount: null,
}


export const mutations = {
    SET_JOBPOST_LIST(state,data){
        state.jobpostList = data
    },
    SET_JOBPOST_COUNT(state, count){
        state.jobpostCount = count
    },
    UPDATE_JOBPOST_LIST(state, data){
      const jobpost_id = data.id
      state.jobpostList = state.jobpostList.filter(jobpost => {
          if(jobpost.id == jobpost_id){
             Object.assign(jobpost, data);
          }
          return jobpost
      });
    },
    ASSIGN_CREATED_JOBPOST(state,data){
        state.jobpostList.push(data)
    }
}

export const actions = {
    getJobpostList({ commit }, {perPage, page, searchQuery} ) {
        ServiceJobpost.getJobpostList(perPage, page, searchQuery)
          .then(resp => {
            if (resp.status == 200){
                commit('SET_JOBPOST_LIST', resp.data.results)
                commit('SET_JOBPOST_COUNT', resp.data.count)
            }
          })
          .catch(err => {
            console.log(err)
          })
    },

    createJobpost({commit}, data){
      return ServiceJobpost.createJobpost(data)
      .then(resp => {
        if(resp.status == 201){
            console.log(resp);
            commit('ASSIGN_CREATED_JOBPOST',resp.data)
            Vue.toasted.success('Jobpost Created Successfully.');
            return resp
        }
      })
    },
    updateJobpost({ commit },{ id , data }){
      return ServiceJobpost.updateJobpost({
        id:id,
        data:data
      })
      .then(resp => {
        if(resp.status == 200){
          Vue.toasted.success('Jobpost Updated Successfully.');
          commit('UPDATE_JOBPOST_LIST',resp.data)
          return resp
        }
      })
    },
    deleteJobpst({commit},{id}){
        return ServiceJobpost.deleteJobpst(id)
        .then(resp => {
          if (resp.status==204){
            Vue.toasted.error('Jobpost Deleted Successfully.');
          }
          return resp
        })
      },
}