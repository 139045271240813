import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_class: 'api/v1/ipanel/classes/',
}

export default {
  getClassList(perPage, page, searchQuery, tutor, student, filter_value, custom_filter_value) {
    var relativeURL = url_list.url_class + '?page_size=' + perPage + '&page=' + page

    if (searchQuery) {
      relativeURL += '&search=' + searchQuery
    }
    if (tutor) {
      relativeURL += '&tutor=' + tutor
    }
    if (student) {
      relativeURL += '&student1=' + student
    }
    if (filter_value) {
      relativeURL += '&completion_status=' + filter_value
    }
    if (custom_filter_value) {
      relativeURL += '&custom_filter_classes=' + custom_filter_value
    }
    return apiClient.get(relativeURL)
  },
  createClass(data) {
    return apiClient.post(url_list.url_class, data)
  },
  getClassDetail(id) {
    return apiClient.get(url_list.url_class + id +'/')
  },
  classUpdate(id, data) {
    return apiClient.patch(url_list.url_class + id +'/' , data)
  },
  createBulkClass(data) {
    return apiClient.post(url_list.url_class  + 'bulk-class-create/', data)
  },
  getClassPackageList(){
    return apiClient.get(url_list.url_class + 'get-class-package-list/')
  },
}