var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container my-4", staticStyle: { "font-size": "14px" } },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { staticClass: "mb-4", attrs: { cols: "12", md: "9" } },
              [
                _vm.isProcessing
                  ? _c(
                      "div",
                      { staticClass: "bg-white shadow-sm" },
                      [
                        _c(
                          "ContentLoader",
                          { attrs: { width: "500", height: "400" } },
                          [
                            _c("rect", {
                              attrs: {
                                x: "0",
                                y: "0",
                                rx: "5",
                                ry: "5",
                                width: "100%",
                                height: "400"
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isProcessing && _vm.postNotFound
                  ? _c("div", { staticClass: "bg-white shadow-sm p-4" }, [
                      _c("h1", { staticClass: "blog-title" }, [
                        _vm._v("Article Not Found")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isProcessing && _vm.post
                  ? _c(
                      "div",
                      { staticClass: "bg-white shadow-sm p-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _c("h1", { staticClass: "blog-title" }, [
                                _c("b", [_vm._v(_vm._s(_vm.post.title))])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-2", attrs: { cols: "12" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "mr-2" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "calendar-alt" }
                                    }),
                                    _vm._v(" "),
                                    _c("small", [_vm._v(_vm._s(_vm.post.date))])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "mr-2" }, [
                                  _c(
                                    "small",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.post.views,
                                          expression: "post.views"
                                        }
                                      ]
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "eye" }
                                      }),
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.post.views) +
                                          "\n                                "
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _c(
                                    "small",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.post.category,
                                          expression: "post.category"
                                        }
                                      ]
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "tag" }
                                      }),
                                      _vm._v(" "),
                                      _vm._l(_vm.post.category, function(
                                        category,
                                        index
                                      ) {
                                        return _c(
                                          "span",
                                          { key: category.id },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-ip",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.gotoCategory(
                                                      category.title
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(category.title))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      index + 1 <
                                                      _vm.post.category.length,
                                                    expression:
                                                      "index + 1 < post.category.length"
                                                  }
                                                ]
                                              },
                                              [_vm._v(", ")]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.convertUrl
                              ? _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("b-embed", {
                                      attrs: {
                                        type: "iframe",
                                        aspect: "16by9",
                                        src: _vm.convertUrl,
                                        allowfullscreen: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mt-2", attrs: { cols: "12" } },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.post.description)
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _vm.post.category && _vm.post.category.length > 0
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "mt-4",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-eye",
                                      attrs: { "aria-hidden": "true" }
                                    }),
                                    _vm._v(" "),
                                    _vm._l(_vm.post.category, function(
                                      category,
                                      index
                                    ) {
                                      return _c(
                                        "span",
                                        { key: index },
                                        [
                                          _c(
                                            "b-link",
                                            {
                                              staticClass: "text-ip",
                                              on: {
                                                click: function($event) {
                                                  return _vm.gotoCategory(
                                                    category.title
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("small", [
                                                _c("b", [
                                                  _vm._v(_vm._s(category.title))
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              index !=
                                              _vm.post.category.length - 1
                                                ? _c("span", [_vm._v(", ")])
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.blogAdHtml
                          ? _c("b-row", { staticClass: "mt-4" }, [
                              _c("div", {
                                staticClass: "m-2 p-4 bg-light rounded w-100",
                                domProps: { innerHTML: _vm._s(_vm.blogAdHtml) }
                              })
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { attrs: { cols: "12", md: "3" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "input-group input-full-width" },
                              [
                                _c("b-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "Search Here"
                                  },
                                  model: {
                                    value: _vm.searchQuery,
                                    callback: function($$v) {
                                      _vm.searchQuery = $$v
                                    },
                                    expression: "searchQuery"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "w-30",
                                        attrs: {
                                          to: {
                                            path:
                                              "/blog/?search=" + _vm.searchQuery
                                          },
                                          type: "submit",
                                          variant: "primary"
                                        }
                                      },
                                      [_c("b", [_vm._v("Search")])]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "bg-white shadow-sm p-4 mx-3 mt-2" },
                      [
                        _c("span", { staticClass: "mt-2" }, [
                          _c("h6", [_c("b", [_vm._v("Categories")])])
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _c(
                              "b-link",
                              {
                                staticClass: "text-ip",
                                attrs: { to: { name: "version2-blog-list" } }
                              },
                              [
                                _vm._v(
                                  "\n                                All, \n                            "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.category_list, function(category, index) {
                          return _c(
                            "span",
                            { key: index },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass: "text-ip",
                                  on: {
                                    click: function($event) {
                                      return _vm.gotoCategory(category.text)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(category.text) +
                                      "\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.category_list.length - 1 != index
                                ? _c("span", [_vm._v(", ")])
                                : _vm._e()
                            ],
                            1
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.latestPostList.length > 0
                      ? _c(
                          "b-col",
                          { staticClass: "mt-4", attrs: { cols: "12" } },
                          [
                            _c("span", [
                              _c("h6", [_c("b", [_vm._v("Latest 6 Articles")])])
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "bg-white shadow-sm p-4 mx-3" },
                      _vm._l(_vm.latestPostList, function(post, index) {
                        return _c(
                          "span",
                          { key: index },
                          [
                            _c(
                              "b-link",
                              {
                                staticClass: "text-ip",
                                on: {
                                  click: function($event) {
                                    return _vm.goto(post)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(post.title) +
                                    "\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.latestPostList.length - 1 != index
                              ? _c("span", [_c("hr")])
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }