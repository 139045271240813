var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-row", [_c("b-col", [_c("h1", [_vm._v("POST")])])], 1),
      _vm._v(" "),
      _c("hr", { staticClass: "mt-0 pt-0" }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "text-right mb-2" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal.modal_create_post",
                        modifiers: { modal_create_post: true }
                      }
                    ],
                    attrs: { variant: "primary", size: "md" }
                  },
                  [_c("b", [_vm._v("CREATE POST")])]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [_c("b-col", { attrs: { cols: "12" } }, [_c("table-post")], 1)],
        1
      ),
      _vm._v(" "),
      _c("modal-create-post", { on: { postCreated: _vm.fetchAllPosts } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }