import ServiceCurriculum from '../../services/ipanel/ServiceCurriculum.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const namespaced = true

export const state = {
    curriculumnList: [],
    curriculumnCount: null,
    curriculumnDetail:null,

    editor: ClassicEditor,
    editorConfig:{
      toolbar: {
          items: [
              "bold",
              "italic",
              "BulletedList",
              "NumberedList",
              "undo",
              "redo"
          ]
      }
    }
    
}

export const mutations = {
    SET_CURRICULUMN_LIST(state, curriculumns) {
        state.curriculumnList = curriculumns
    },
    UNSET_CURRICULUMN_LIST(state) {
        state.curriculumnList = []
    },
    SET_CURRICULUMN_COUNT(state, count) {
        state.curriculumnCount = count
    },
    UNSET_CURRICULUMN_COUNT(state) {
        state.curriculumnCount = null
    },
    SET_CURRICULUMN_DETAIL(state, curriculumn) {
        state.curriculumnDetail = curriculumn
    },
    UNSET_CURRICULUMN_DETAIL(state) {
        state.curriculumnDetail = null
    },
    ADD_CURRICULUMN(state, curriculumn) {
        state.curriculumnList.unshift(curriculumn)
    },
    SET_UPDATED_CURRICULUMN(state, data){
        const curriculumn_id = data.id
        state.curriculumnList = state.curriculumnList.filter(curriculumn => {
            if(curriculumn.id == curriculumn_id){
                Object.assign(curriculumn, data);
            }
            return curriculumn
        });
    },
    UPDATE_DISCOUNT_DETAIL(state, data){
        state.curriculumnDetail = data
    },
}

export const actions = {
    getCurriculumnList({
        commit
    }, {
        perPage,
        page,
        searchQuery
    }) {
        ServiceCurriculum.getCurriculumnList(perPage, page, searchQuery)
            .then(resp => {
                commit('SET_CURRICULUMN_LIST', resp.data.results)
                commit('SET_CURRICULUMN_COUNT', resp.data.count)
            })
            .catch(err => {
                console.log(err)
            })
    },

    createCurriculumn({
        commit
    }, data) {
        ServiceCurriculum.createCurriculumn(data)
            .then(resp => {
                if (resp.status == 201) {
                    commit('ADD_CURRICULUMN', resp.data)
                    Vue.toasted.success('Curriculumn created Successfully');
                }
            })
            .catch(err => {
                console.log(err)
            })
    },

    getCurriculumnDetail({ commit }, curriculumn_id) {
        return ServiceCurriculum.getCurriculumnDetail(curriculumn_id)
            .then(resp => {
                if (resp.status == 200) {
                    commit('SET_CURRICULUMN_DETAIL', resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },
    updateCurriculumn({  commit }, { curriculumn_id, data }) {
        return ServiceCurriculum.updateCurriculumn({
            curriculumn_id,
            data
        })
        .then(resp => {
            if (resp.status == 200) {
                commit('SET_UPDATED_CURRICULUMN', resp.data)
                commit('UPDATE_CURRICULUMN_DETAIL', resp.data)
            }
            Vue.toasted.success('Curriculumn Updated Successfully.');
        })
        .catch(err => {
            console.log(err)
        })
    },
}

export const getters = {}