import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_user_info: '/api/v1/ipanel/user-permission',
}

export default {
    getUserInfo() {
        var relativeURL = url_list.url_user_info
        return apiClient.get(relativeURL)
      },
}