import ServiceExpense from '../../services/ipanel/ServiceExpense.js'
export const namespaced = true

export const state = {
  expenseList: [],
  expenseCount: null,
}


export const mutations = {
    SET_EXPENSE_LIST(state,data){
        state.expenseList = data
    },
    SET_EXPENSE_COUNT(state, count){
        state.expenseCount = count
    },
    UPDATE_EXPENSE_LIST(state, data){
      const expense_id = data.id
      state.expenseList = state.expenseList.filter(expense => {
          if(expense.id == expense_id){
             Object.assign(expense, data);
          }
          return expense
      });
    },
}

export const actions = {
    getExpenseList({ commit }, {perPage, page, searchQuery} ) {
        ServiceExpense.getExpenseList(perPage, page, searchQuery)
          .then(resp => {
            commit('SET_EXPENSE_LIST', resp.data.results)
            commit('SET_EXPENSE_COUNT', resp.data.count)
          })
          .catch(err => {
            console.log(err)
          })
    },

    createExpense({commit}, data){
      return ServiceExpense.createExpense(data)
      .then(resp => {
        if(resp.status == 201){
            Vue.toasted.success('Expense Created Successfully.');
            return resp
        }
      })
    },
    updateExpense({ commit },{ id , data }){
      return ServiceExpense.updateExpense({
        id:id,
        data:data
      })
      .then(resp => {
        if(resp.status == 200){
          Vue.toasted.success('Invoice Updated Successfully.');
          commit('UPDATE_EXPENSE_LIST',resp.data)
          return resp
        }
      })
    },
}