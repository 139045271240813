import ServiceAdminUser from '../../services/ipanel/ServiceAdminUser.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const namespaced = true

export const state = {
    groupList: [],
    permissionList: [],
    adminUserList: [],
    adminUserCount: null,
}

export const mutations = {
    SET_GROUP_LIST(state, groups) {
        state.groupList = groups
    },
    SET_PERMISSION_LIST(state, permissions) {
        state.permissionList = permissions
    },
    SET_ADMIN_USER_LIST(state, admins) {
        state.adminUserList = admins
    },
    SET_ADMIN_USER_COUNT(state, count) {
        state.adminUserCount = count
    },
}

export const actions = {
    getGroupList({commit}) {
        ServiceAdminUser.getGroupList()
            .then(resp => {
                commit('SET_GROUP_LIST', resp.data.permission_groups)
            })
            .catch(err => {
                console.log(err)
            })
    },
    getPermissionList({commit}) {
        ServiceAdminUser.getPermissionList()
            .then(resp => {
                commit('SET_PERMISSION_LIST', resp.data.permissions)
            })
            .catch(err => {
                console.log(err)
            })
    },
    getAdminUserList({commit},{perPage,page}) {
        ServiceAdminUser.getAdminUserList(perPage, page)
            .then(resp => {
                commit('SET_ADMIN_USER_LIST', resp.data.results)
                commit('SET_ADMIN_USER_COUNT', resp.data.count)
            })
            .catch(err => {
                console.log(err)
            })
    },
}

export const getters = {}