import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  url_expense: '/api/v1/ipanel/expense/',
}

export default {
    getExpenseList(perPage, page, searchQuery) {
      var relativeURL = url_list.url_expense + '?page_size=' + perPage + '&page=' + page
      if (searchQuery) {
        relativeURL += '&search=' + searchQuery
      }
      return apiClient.get(relativeURL)
    },
    createExpense(data) {
      return apiClient.post(url_list.url_expense, data)
    },
    updateExpense(form_data) {
      return apiClient.patch(url_list.url_expense + form_data.id + '/', form_data.data)
    },
}