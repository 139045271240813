import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST
})

const url_list = {
  job_list: '/api/v1/operation/tutor-jobs/',
}

export default {
    getJobList({perPage, page, searchQuery}) {
        var relativeURL = url_list.job_list + '?page_size=' + perPage + '&page=' + page
        if (searchQuery) {
            relativeURL += '&search=' + searchQuery
        }
        return apiClient.get(relativeURL)
    },
    createTutorJob(data){
        return apiClient.post(url_list.job_list, data)
    },
    updateTutorJob(data){
      return apiClient.patch(url_list.job_list + data.unique_code + "/", data)
  }
}