import ServicePartner from '../../services/ipanel/ServicePartner.js'
export const namespaced = true

export const state = {
  partnerList: [],
  partnerCount: null,
}


export const mutations = {
    SET_PARTNER_LIST(state,data){
        state.partnerList = data
    },
    SET_PARTNER_COUNT(state, count){
        state.partnerCount = count
    },
    UPDATE_PARTNER_LIST(state, data){
      const partner_id = data.id
      state.partnerList = state.partnerList.filter(partner => {
          if(partner.id == partner_id){
             Object.assign(partner, data);
          }
          return partner
      });
    },
    ASSIGN_CREATED_PARTNER(state,data){
        state.partnerList.push(data)
    }
}

export const actions = {
    getPartnerList({ commit }, {perPage, page, searchQuery} ) {
        ServicePartner.getPartnerList(perPage, page, searchQuery)
          .then(resp => {
            if (resp.status == 200){
                commit('SET_PARTNER_LIST', resp.data.results)
                commit('SET_PARTNER_COUNT', resp.data.count)
            }
          })
          .catch(err => {
            console.log(err)
          })
    },

    createPartner({commit}, data){
      return ServicePartner.createPartner(data)
      .then(resp => {
        if(resp.status == 201){
            console.log(resp);
            commit('ASSIGN_CREATED_PARTNER',resp.data)
            Vue.toasted.success('Partner Created Successfully.');
            return resp
        }
      })
    },
    updatePartner({ commit },{ id , data }){
      return ServicePartner.updatePartner({
        id:id,
        data:data
      })
      .then(resp => {
        if(resp.status == 200){
          Vue.toasted.success('Partner Updated Successfully.');
          commit('UPDATE_PARTNER_LIST',resp.data)
          return resp
        }
      })
    },
}