import ServiceCertificate from '../../services/ipanel/ServiceCertificate.js'
import ServiceStudent from '../../services/ipanel/ServiceStudent.js'
import ServiceTutor from '../../services/ipanel/ServiceTutor.js'

export const namespaced = true

export const state = {
  certificateList: [],
  certificateCount: null,
  certificateDetail: null,
  studentList: [],
  studentListChoices: [],

  // tutor certificate data
  tutorList: [],
  tutorCertificateList: [],
  tutorCertificateCount: null,
  tutorListChoices: []
}

export const mutations = {
  SET_CERTIFICATE_LIST(state, data) {
    state.certificateList = data
  },
  ADD_ITEM_TO_CERTIFICATE_LIST(state, data) {
    state.certificateList.unshift(data)
  },
  SET_CERTIFICATE_COUNT(state, count) {
    state.certificateCount = count
  },
  INCREASE_CERTIFICATE_COUNT(state) {
    state.certificateCount += 1
  },
  SET_STUDENT_LIST(state, data) {
    state.studentList = data
  },
  SET_STUDENT_LIST_CHOICES(state, data){
    state.studentListChoices = []
    data.forEach(element=>{
      state.studentListChoices.push({ id: element.id, text:element.name })
    })
  },


  // tutor certificate mutations
  SET_TUTOR_CERTIFICATE_LIST(state, data) {
    state.tutorCertificateList = data
  },
  SET_TUTOR_CERTIFICATE_COUNT(state, count) {
    state.tutorCertificateCount = count
  },
  SET_TUTOR_LIST(state, data) {
    state.tutorList = data
  },
  ADD_ITEM_TO_TUTOR_CERTIFICATE_LIST(state, data) {
    state.tutorCertificateList.unshift(data)
  },
  INCREASE_TUTOR_CERTIFICATE_COUNT(state) {
    state.tutorCertificateCount += 1
  },
  SET_TUTOR_LIST_CHOICES(state, data){
    state.tutorListChoices = []
    data.forEach(element=>{
      state.tutorListChoices.push({id: element.id, text: element.name})
    })
  },
}

export const actions = {
  getCertificateList({
    commit
  }, {
    perPage,
    page,
    searchQuery
  }) {
    ServiceCertificate.getCertificateList(perPage, page, searchQuery)
      .then(resp => {
        commit('SET_CERTIFICATE_LIST', resp.data.results)
        commit('SET_CERTIFICATE_COUNT', resp.data.count)
      })
      .catch(err => {
        console.log(err)
      })
  },
  getStudentList({
    commit
  }) {
    var perPage = 1000
    var page = 1
    var searchQuery = ""
    ServiceStudent.getStudentList(perPage, page, searchQuery)
      .then(resp => {

        commit('SET_STUDENT_LIST', resp.data)
      })
  },
  createCertificate({
    commit
  }, data) {
    ServiceCertificate.createCertificate(data)
      .then(resp => {
        if (resp.status == 201) {
          commit('ADD_ITEM_TO_CERTIFICATE_LIST', resp.data)
          commit('INCREASE_CERTIFICATE_COUNT')
          Vue.toasted.success('Certificate Created Successfully.');
        }
      })
  },

  // actions for tutor certificate
  getTutorCertificateList({
    commit
  }, {
    perPage,
    page,
    searchQuery
  }) {
    ServiceCertificate.getTutorCertificateList(perPage, page, searchQuery)
      .then(resp => {
        commit('SET_TUTOR_CERTIFICATE_LIST', resp.data.results)
        commit('SET_TUTOR_CERTIFICATE_COUNT', resp.data.count)
      })
      .catch(err => {
        console.log(err)
      })
  },
  getTutorList({
    commit
  }, {perPage, page}) {
    ServiceTutor.getTutorList(perPage, page)
      .then(resp => {
        commit('SET_TUTOR_LIST', resp.data.results)
      })
  },
  createTutorCertificate({
    commit
  }, data) {
    ServiceCertificate.createTutorCertificate(data)
      .then(resp => {
        if (resp.status == 201) {
          commit('ADD_ITEM_TO_TUTOR_CERTIFICATE_LIST', resp.data)
          commit('INCREASE_TUTOR_CERTIFICATE_COUNT')
          Vue.toasted.success('Certificate Created Successfully.');
        }
      })
  },
  getStudentListChoices({commit}) {
    ServiceStudent.getStudentListChoices()
      .then(resp => {
        commit('SET_STUDENT_LIST_CHOICES', resp.data)
      })
  },
  getTutorListChoices({commit}){
    ServiceTutor.getTutorListChoices()
    .then(resp => {
      console.log(resp)
      commit('SET_TUTOR_LIST_CHOICES', resp.data)
    })
  }

}

export const getters = {}